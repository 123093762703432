import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { Icon } from "@iconify-icon/react";

import { CreateStore, InvitationModal, MainLoader } from "../../components";
import {
  CalendarIcon,
  Dashboard,
  Logout,
  OpenBox,
  Users,
  Video,
  Settings,
  SwitchArrows,
  Bell,
  UserCircle,
  StoreIcon,
  Landing,
  TV,
} from "../../components/FiraIcons";
import SvgMail from "../../components/FiraIcons/Mail";
import SettingsModal from "../../components/Modals/SettingsModal/SettingsModal";
import { logoutService } from "../../services/authentication";
import { getOwnerStoresActionCreator } from "../../store/Brand/BrandActions";
import {
  getInvitationListActionCreator,
  getUserActionCreator,
} from "../../store/User/UserActions";
import { NavLink, RootState, SettingElements } from "../../utils/types";
import "./HomeLayout.scss";
import { useSearchParams } from "react-router-dom";
import FiraTopBar from "../../components/FiraTopBar/FiraTopBar";
import FiraSideBar from "../../components/FiraSideBar/FiraSideBar";
import FiraTopContextMenu from "../../components/FiraTopBar/FiraTopContextMenu/FiraTopContextMenu";
import { handleImageLoadError } from "../../utils/handleImageLink";
import { logoutActionCreator } from "../../store/Authentication/actions";
import {
  StoreProfileSettings,
  MainStoreSettings,
} from "../../components/Settings";
import UserRolesAndPermissions from "../../components/Settings/UserRolesAndPermissions/UserRolesAndPermissions";
import { setSettingsModalStateActionCreator } from "../../store/Utils/UtilsActions";
import UserProfile from "../../components/Settings/UserProfile/UserProfile";
import { FiraUserRole } from "../../utils/enums";
import operatorPermissions, {
  FIRA_PERMISSIONS_GROUP,
  FIRA_WEB_DASHBOARD_PERMISSIONS,
  FIRA_WEB_MANAGE_PRODUCTS_PERMISSIONS,
  FIRA_WEB_VIEW_EVENTS_PERMISSIONS,
} from "../../utils/operatorPermissions";
import { WizardProvider, WizardStep } from "../../providers/WizardProvider";
import EventWizard from "../../components/EventWizard/EventWizard";
import SettingTabsLayout from "../SettingTabsLayout/SettingTabsLayout";
import EventPermissions from "../../components/Settings/Event/EventPermissions";
import StepColorPicker from "../../components/EventWizard/StepColorPicker";
import StepButtonText from "../../components/EventWizard/StepButtonText";
import StepOrientation from "../../components/EventWizard/StepOrientation";
import StepLogin from "../../components/EventWizard/StepLogin";
import SettingsStepLayout from "../SettingsStepLayout/SettingsStepLayout";
import SettingsLandingModule from "../../components/SettingsLandingModule/SettingsLandingModule";
import SettingsLandingPermissions from "../../components/SettingsLandingModule/SettingsLandingPermissions";
import { SettingsLandingProvider } from "../../providers/SettingsLandingProvider";
import ProductPermissions from "../../components/Settings/Product/ProductPermissions";
import AdditionalVars from "../../components/Settings/AdditionalVars/AdditionalVars";
import { useUtils } from "../../providers/UtilsProvider";
import { StoreType } from "../../types/Stores.d";
//import { GeneralContext } from "../../context/GeneralContext";

const HomeLayout = () => {
  const [pickStoreIsOpen, setPickStoreIsOpen] = useState(false);
  const [createStoreIsOpen, setCreateStoreIsOpen] = useState(false);
  const [invitationModalIsOpen, setInvitationModalIsOpen] = useState(false);
  const { authentication, brand, user, utils } = useSelector(
    (state: RootState) => state
  );
  const dispatch = useDispatch();
  const { loadCountryCodes } = useUtils();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  //const { resetContext } = useContext<any>(GeneralContext);

  const nuvemShopCode = searchParams.get("code");

  const invitations = {
    name: t("components.navBar.contextMenu.invitationsButton"),
    icon: <SvgMail />,
    clickHandler: () => {
      authentication.user &&
        dispatch(getInvitationListActionCreator(authentication.user.email));
      setInvitationModalIsOpen(true);
    },
    to: "",
  };

  const changebrand = {
    name: t("components.navBar.contextMenu.changeBrandButton"),
    icon: <SwitchArrows />,
    clickHandler: () => {
      setPickStoreIsOpen(true);
    },
    to: "",
  };

  const logout = {
    name: t("components.navBar.logoutButton"),
    icon: <Logout />,
    clickHandler: () => {
      logoutHandler();
    },
    to: "",
  };

  // const optionsMenuLinks = authentication.isAccountOwner
  //   ? [logout, invitations]
  //   : [invitations, changebrand, logout];
  // Lo dejo comentado, porque no hay ninguna particularidad aún que diferencie este menú entre el account owner y el resto de las cuentas.

  const optionsMenuLinks = [invitations, logout];

  const [navButtons, setNavButtons] = useState<NavLink[]>([]);

  const logoutHandler = async () => {
    authentication.sessionId && (await logoutService(authentication.sessionId));
    dispatch(logoutActionCreator());
  };

  const [settingsModalIsOpen, setSettingsModalIsOpen] =
    useState<boolean>(false);

  const openSettingsModal = () => {
    dispatch(setSettingsModalStateActionCreator(true));
  };

  const closeSettingsModal = () => {
    dispatch(setSettingsModalStateActionCreator(false));
  };

  //Settings Menu elements

  const userProfile = {
    title: "components.navBar.storeSettingButton",
    component: <UserProfile />,
    id: "accountOwnerProfile",
  };
  const userRoles = {
    title: "components.navBar.profileRolesAndPermissions",
    component: <UserRolesAndPermissions />,
    id: "RolesAndPermissions",
  };

  const profileSettingsSubEl = authentication.isAccountOwner
    ? [userProfile]
    : [userProfile, userRoles];

  const profileSettings = {
    title: "components.navBar.profileSettingsButton",
    subElements: profileSettingsSubEl,
    icon: <UserCircle />,
  };

  const componentSteps = [
    <StepColorPicker />,
    <StepButtonText />,
    <StepOrientation />,
    <StepLogin />,
  ];
  const eventsComponents = [
    {
      tabTitle: t("components.settings.event.tabs.permissions"),
      component: <EventPermissions />,
    },
    {
      tabTitle: t("components.settings.event.tabs.graphicConfig"),
      component: <EventWizard components={componentSteps} />,
    },
  ];

  const wizardSteps = [
    WizardStep.COLOR,
    WizardStep.BUTTON,
    WizardStep.ORIENTATION,
    WizardStep.LOGIN,
  ];

  const eventsSettings = {
    title: t("components.settings.sidebar.events"),
    component: (
      <WizardProvider steps={wizardSteps}>
        <SettingTabsLayout
          title={t("components.settings.event.title")}
          components={eventsComponents}
        />
      </WizardProvider>
    ),
    id: "EventsSettings",
  };

  const landingSettings = {
    title: t("components.settings.sidebar.landing"),
    component: (
      <SettingsStepLayout title={t("components.settings.landing.title")}>
        <SettingsLandingModule />
        <SettingsLandingPermissions />
      </SettingsStepLayout>
    ),
    id: "LandingSettings",
  };

  const productSettings = {
    title: t("components.settings.sidebar.product"),
    component: <ProductPermissions />,
    id: "ProductSettings",
  };

  const addionalsVars = {
    title: t("components.settings.sidebar.additionals"),
    component: <AdditionalVars />,
    id: "VarsSettings",
  };

  const modules = [
    productSettings,
    eventsSettings,
    landingSettings,
    addionalsVars,
  ];

  const modulesSettings = {
    title: t("components.settings.sidebar.modules"),
    subElements: modules,
    icon: <Icon icon="bx:world" />,
  };

  const storeSettings = {
    title: "components.navBar.storeInfoButton",
    subElements: [
      {
        title: "components.navBar.storeSettingButton",
        component: (
          <>
            <StoreProfileSettings />
          </>
        ),
        id: "storeGeneral",
      },
    ],
    icon: <StoreIcon />,
  };

  const mainStoreSettings = {
    title: "components.navBar.storeInfoButton",
    subElements: [
      {
        title: "components.navBar.storeSettingButton",
        component: <MainStoreSettings />,
        id: "storeGeneral",
      },
    ],
    icon: <StoreIcon />,
  };

  // const sidebarElementsInitialState: SettingElements[] = [
  //   {
  //     title: "components.navBar.profileSettingsButton",
  //     subElements: [
  //       // {
  //       //   title: "components.navBar.storeSettingButton",
  //       //   component: <UserProfileSettings />,
  //       //   id: "profileGeneral",
  //       // }, //IMPORTANT DO NOT DELETE THIS

  //       {
  //         title: "components.navBar.storeSettingButton",
  //         component: <AccountOwnetUserProfileSettings />,
  //         id: "accountOwnerProfile",
  //       },
  //       // {
  //       //   title: "components.navBar.userSecurityButton",
  //       //   component: <UserSecuritySettings />,
  //       //   id: "userSecurity",
  //       // },
  //       {
  //         title: "components.navBar.profileRolesAndPermissions",
  //         component: <UserRolesAndPermissions />,
  //         id: "RolesAndPermissions",
  //       },
  //     ],
  //     icon: <UserCircle />,
  //   },
  //   {
  //     title: "components.navBar.storeInfoButton",
  //     subElements: [
  //       {
  //         title: "components.navBar.storeSettingButton",
  //         component: (
  //           <>
  //             <SettingsStore />
  //           </>
  //         ),
  //         id: "storeGeneral",
  //       },
  //     ],
  //     icon: <StoreIcon />,
  //   },
  // ];

  const [sidebarElements, setSidebarItems] = useState<SettingElements[]>([
    profileSettings,
  ]);

  const bottomNavButtoms: NavLink[] = [
    {
      icon: (
        <div
          style={{
            width: "18px",
            height: "18px",
            fontSize: "18px",
            marginTop: "6px",
          }}
        >
          <Settings />
        </div>
      ),
      name: t("components.navBar.settingButton"),
      to: "#",
      clickHandler: () => {
        openSettingsModal();
      },
    },
  ];

  const closePickStoreModal = () => {
    setPickStoreIsOpen(false);
  };
  const closeCreateStoreModal = () => {
    setCreateStoreIsOpen(false);
  };

  const openCreateStoreModal = () => {
    closePickStoreModal();
    setCreateStoreIsOpen(true);
  };

  const closeInvitationModal = () => {
    setInvitationModalIsOpen(false);
  };

  const subLink = [
    {
      name: t("views.collections.title"),
      to: "/home/collections",
    },
    {
      name: t("views.firaTv.title"),
      to: "/home/widgets",
    },
  ];

  // for account owner mode
  const mainboard: NavLink = {
    icon: <Dashboard />,
    name: t("views.dashboard.title"),
    to: "/home/mainboard",
  };
  const allVideos: NavLink = {
    icon: <Icon icon="bi:camera-video-fill" />,
    name: t("views.videos.route"),
    to: "/home/all-videos",
  };
  const team: NavLink = {
    icon: <Users />,
    name: t("views.team.title"),
    to: "/home/team",
  };
  const stores: NavLink = {
    icon: <StoreIcon />,
    name: t("views.stores.title"),
    to: "/home/stores",
  };

  const calendar: NavLink = {
    icon: <CalendarIcon />,
    name: t("views.ownerCalendar.title"),
    to: "/home/calendar",
  };

  // for all users
  const dashboard: NavLink = {
    icon: <Dashboard />,
    name: t("views.dashboard.title"),
    to: "/home/dashboard",
  };
  const events: NavLink = {
    icon: <CalendarIcon />,
    name: t("views.events.title"),
    to: "/home/events",
  };
  const users: NavLink = {
    icon: <Users />,
    name: t("views.users.title"),
    to: "/home/team",
  };
  const products: NavLink = {
    icon: <OpenBox />,
    name: t("views.products.title"),
    to: "/home/products",
  };
  //globalProducts
  const globalProducts: NavLink = {
    icon: <OpenBox />,
    name: t("views.products.title"),
    to: "/home/all-products",
  };
  const videos: NavLink = {
    icon: <TV />,
    name: t("views.videos.route"),
    to: "/home/videos",
    subLink,
  };

  const landing: NavLink = {
    icon: <Landing height={24} width={24} />,
    name: t("views.landing.title"),
    to: "/home/landing",
  };

  const setNavbarButtonsByRole = () => {
    if (authentication.isAccountOwner) {
      if (brand.currentStore) {
        setNavButtons([dashboard, events, users, products, videos, landing]);
      } else {
        setNavButtons([
          mainboard,
          stores,
          calendar,
          team,
          globalProducts,
          allVideos,
        ]);
      }
    } else if (authentication.currentRole === FiraUserRole.MANAGER) {
      setNavButtons([dashboard, events, users, products, videos]);
      if (brand.currentStore) {
        setNavButtons([dashboard, events, users, products, videos, landing]);
      }
    } else if (authentication.currentRole === FiraUserRole.MODERATOR) {
      if (authentication.currentPermission) {
        let moderatorViews: NavLink[] = [];
        operatorPermissions(
          authentication.currentPermission,
          "group",
          FIRA_PERMISSIONS_GROUP.FIRA_WEB
        ) && moderatorViews.push(events);

        operatorPermissions(
          authentication.currentPermission,
          "group",
          FIRA_PERMISSIONS_GROUP.FIRA_TV
        ) && moderatorViews.push(videos);

        setNavButtons(moderatorViews);
      }
    } else {
      if (authentication.currentPermission) {
        let operatorViews: NavLink[] = [];
        operatorPermissions(
          authentication.currentPermission,
          "permission",
          FIRA_WEB_DASHBOARD_PERMISSIONS.EVENT_GET_STATS
        ) && operatorViews.push(dashboard);
        operatorPermissions(
          authentication.currentPermission,
          "permission",
          FIRA_WEB_VIEW_EVENTS_PERMISSIONS.EVENT_USER
        ) && operatorViews.push(events);
        operatorPermissions(
          authentication.currentPermission,
          "permission",
          FIRA_WEB_MANAGE_PRODUCTS_PERMISSIONS.GET_ALL_FIRA_PRODUCT
        ) && operatorViews.push(products);
        operatorPermissions(
          authentication.currentPermission,
          "group",
          FIRA_PERMISSIONS_GROUP.FIRA_TV
        ) && operatorViews.push(videos);
        operatorPermissions(
          authentication.currentPermission,
          "permission",
          "LANDING_UPDATE_BY_ID"
        ) && operatorViews.push(landing);
        setNavButtons(operatorViews);
      }
    }
  };

  useEffect(() => {
    authentication.user &&
      dispatch(getUserActionCreator(authentication.user.id));

    // CHECK FOR ACCOUNT OWNER
    if (authentication.isAccountOwner && !brand.currentStore) {
      authentication.user?.businessClientId &&
        dispatch(
          getOwnerStoresActionCreator(authentication.user.businessClientId)
        );
    }
  }, [authentication.user, dispatch]);

  useEffect(() => {
    if (brand.currentStore) {
      const isMainStore = brand.currentStore.storeType === StoreType.MAIN;
      if (authentication.currentRole === FiraUserRole.ACCOUNT_OWNER) {
        if (isMainStore)
          setSidebarItems([
            profileSettings,
            mainStoreSettings,
            modulesSettings,
          ]);
        else setSidebarItems([profileSettings, storeSettings, modulesSettings]);
      } else if (
        authentication.currentRole === FiraUserRole.MANAGER &&
        !isMainStore
      ) {
        setSidebarItems([profileSettings, storeSettings]);
      } else {
        setSidebarItems([profileSettings]);
      }
    } else {
      if (authentication.currentRole === FiraUserRole.ACCOUNT_OWNER) {
        setSidebarItems([profileSettings, modulesSettings]);
      }
    }
  }, [brand]);

  useEffect(() => {
    if (authentication.isAccountOwner) {
      if (brand.currentStore) {
        navigate("dashboard");
      } else {
        navigate("mainboard");
      }
    }

    setNavbarButtonsByRole();
  }, [authentication.isAccountOwner]);

  useEffect(() => {
    if (authentication.currentPermission) {
      if (authentication.currentRole === FiraUserRole.MODERATOR) {
        if (
          operatorPermissions(
            authentication.currentPermission,
            "group",
            FIRA_PERMISSIONS_GROUP.FIRA_WEB
          )
        ) {
          navigate("events");
        } else {
          navigate("videos");
        }
      } else if (authentication.currentRole === FiraUserRole.OPERATOR) {
        navigate("dashboard");
      } else if (authentication.currentRole === FiraUserRole.MANAGER) {
        navigate("dashboard");
      }
    }
  }, [authentication.currentPermission]);

  useEffect(() => {
    setNavbarButtonsByRole();
  }, [brand, authentication.user]);

  useEffect(() => {
    if (utils.settingsModalIsOpen) {
      setSettingsModalIsOpen(true);
    } else {
      setSettingsModalIsOpen(false);
    }
  }, [utils.settingsModalIsOpen]);

  useEffect(() => {
    return () => {
      dispatch(setSettingsModalStateActionCreator(false));
    };
  }, []);

  useEffect(() => {
    const prevRoute = localStorage.getItem("route");
    loadCountryCodes();
    if (prevRoute !== null) {
      setTimeout(() => {
        //resetContext();
        navigate(`/home/${prevRoute}`);
      }, 3000);
    }
    return () => {
      if (prevRoute !== null) {
        localStorage.removeItem("route");
      }
    };
  }, []);

  return (
    <SettingsLandingProvider>
      {!location.pathname.includes("landing/config") && (
        <FiraTopBar>
          <Notifications />
          <UserInfo options={optionsMenuLinks} />
        </FiraTopBar>
      )}

      <div className="Home">
        <FiraSideBar
          topNavButtons={navButtons}
          bottomNavButtons={bottomNavButtoms}
        />
        <Outlet />

        {brand.isLoading && !authentication.isAccountOwner && <MainLoader />}

        {createStoreIsOpen && (
          <CreateStore
            isOpen={createStoreIsOpen}
            onClose={closeCreateStoreModal}
          />
        )}
        {invitationModalIsOpen && (
          <InvitationModal
            isOpen={invitationModalIsOpen}
            onClose={closeInvitationModal}
          ></InvitationModal>
        )}
        {utils.settingsModalIsOpen && (
          <SettingsModal
            isOpen={utils.settingsModalIsOpen}
            onClose={closeSettingsModal}
            settingElements={sidebarElements}
            setSettingElements={setSidebarItems}
            nuvemShopCode={nuvemShopCode}
          />
        )}
      </div>
    </SettingsLandingProvider>
  );
};

interface UserInfoProps {
  options: NavLink[];
}

const UserInfo = ({ options }: UserInfoProps) => {
  const { user, utils, authentication } = useSelector(
    (state: RootState) => state
  );
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [name, setName] = useState<{
    firstname: string;
    lastname: string;
  }>();

  const getInitials = () => {
    const initialFirstName = name?.firstname.charAt(0);
    const initialLastName = name?.lastname.charAt(0);

    if (initialLastName) {
      return initialFirstName + initialLastName;
    } else {
      return initialFirstName;
    }
  };

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    if (utils.navBarIsClosed) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [utils.navBarIsClosed]);

  useEffect(() => {
    if (user.info) {
      setName({
        firstname: user.info.firstName ? user.info.firstName : "",
        lastname: user.info.lastName ? user.info.lastName : "",
      });
    } else if (authentication.user) {
      setName({
        firstname: authentication.user.firstName
          ? authentication.user.firstName
          : "",
        lastname: authentication.user.lastName
          ? authentication.user.lastName
          : "",
      });
    }
  }, [authentication.user, user.info]);

  return (
    <>
      <div className={"UserSection"}>
        <div className={"FiraUserWrapper"} onClick={toggleMenu}>
          <div className={"FiraUserWrapper__Avatar"}>
            {user.info?.avatar ? (
              <img
                src={user.info.avatar}
                alt="avatar"
                onError={handleImageLoadError}
              />
            ) : (
              getInitials()
            )}
          </div>

          <div className={"FiraUserWrapper__Info"}>
            {user.info?.firstName && user.info.lastName ? (
              <span>
                {user.info?.firstName} {user.info?.lastName}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        {openMenu && (
          <FiraTopContextMenu
            options={options}
            closeModal={() => setOpenMenu(false)}
          />
        )}
      </div>
    </>
  );
};

const Notifications = () => {
  return (
    <div className={"Notifications"}>
      <Bell />
    </div>
  );
};

export default HomeLayout;
