import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { usePopper } from "react-popper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  convertDateUTC,
  dateFormat,
  timeZoneDate,
} from "../../utils/handleDates";
import { FiraEventForm, RootState } from "../../utils/types";
import Button from "../Button/Button";
import { SimpleX, TimeClock } from "../FiraIcons";

import Input from "../Input/Input";
import InputTime from "../InputTime/InputTime";
import "./PopUpCreateEvent.scss";
import "./calendar-date.scss";
import DateIcon from "../FiraIcons/DateIcon";
import {
  createMassiveEventActionCreator,
  postCreateEventActionCreator,
  resetEventMessageActionCreator,
} from "../../store/Event/EventActions";
import { VirtualElement } from "@popperjs/core";
import { EventType } from "../../utils/enums";
import { StoreType } from "../../types/Stores.d";

interface EventCreateProps {
  x?: number;
  y?: number;
  startDateSelected?: Date;
  elementRef?: HTMLElement | undefined;
  onClose(): void;
}

interface FiraEventTemp {
  storeId?: string;
  eventName: string;
  startDate: Date;
  endDate: Date;
}
/**
 * Descripcion de componente
 * @prop {number} x - indica la posicion X del elemento seleccionado (solo para elemento Virtual)
 * @prop {number} y - indica la posicion Y del elemento seleccionado (solo para elemento Virtual)
 * @prop {Date} startDateSelected - Indica la fecha seleccionada (solo para elemento Virtual)
 * @prop {HTMLElement} elementRef - Indica la referencia del elemento HTML
 * @prop {function()} onClose - Evento de cierra el popup
 * */
const PopUpCreateEventTop = ({
  x,
  y,
  startDateSelected,
  elementRef,
  onClose,
}: EventCreateProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialState: FiraEventTemp = {
    storeId: "",
    eventName: "",
    startDate: startDateSelected ? startDateSelected : new Date(),
    endDate: new Date(),
  };
  const initialStateForm: FiraEventForm = {
    storeId: "",
    eventName: "",
    eventDateTimeRange: {
      startDate: new DateObject().format("DD/MM/YY"),
      endDate: "",
    },
  };
  const { brand, event } = useSelector((state: RootState) => state);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const [formValidated, setFormValidated] = useState<boolean>(false);
  const [form, setForm] = useState<FiraEventTemp>(initialState);
  const [formSend, setFormSend] = useState<FiraEventForm>(initialStateForm);
  const [timer, setTimer] = useState<boolean>(false);
  const weekDays = ["D", "L", "M", "M", "J", "V", "S"];
  const [elementVirtual, setElementVirtual] = useState<VirtualElement>({
    getBoundingClientRect: () => new DOMRect(x, y),
  });
  //Popper Modifiers Definition
  const modifiers = !startDateSelected
    ? [
        {
          name: "offset",
          options: {
            offset: [-140, 0],
          },
        },
        {
          name: "preventOverflow",
          options: {
            padding: 10,
          },
        },
        {
          name: "arrow",
          options: {
            element: arrowElement,
          },
        },
      ]
    : [
        {
          name: "arrow",
          options: {
            element: arrowElement,
          },
        },
      ];

  const { styles, attributes } = usePopper(
    elementRef ? elementRef : elementVirtual,
    popperElement,
    {
      modifiers,
    }
  );

  const containerStyle: any = {
    ...styles.popper,
    display: "flex",
    zIndex: 999,
    flexDirection: "column",
    borderRadius: "4px",
    padding: "10px",
  };

  const handleCloseClick = () => {
    onClose();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    switch (name) {
      case "name":
        setForm({ ...form, eventName: value });
        break;
      case "startTime":
        if (value) {
          changeDate(value);
          setTimer(true);
        } else {
          setTimer(false);
          setFormValidated(false);
        }
        break;
    }
  };

  const changeDate = (time: string) => {
    const newTime = time.split(":");
    const h = Number(newTime[0]);
    const min = Number(newTime[1]);

    form.startDate.setHours(h, min);
    const fecha = new Date(form.startDate);
    setForm({ ...form, startDate: fecha });

    const fechaFin = new Date(form.startDate);
    fechaFin.setHours(h + 1, min);
    setForm({ ...form, endDate: fechaFin });
  };

  const handleSubmit = (e: React.FormEvent, eventType: EventType) => {
    e.preventDefault();
    setForm({ ...form, storeId: brand.currentStore?.id });

    if (brand.currentStore && brand.currentStore.storeType === StoreType.MAIN)
      dispatch(createMassiveEventActionCreator(formSend, eventType));
    else dispatch(postCreateEventActionCreator(formSend, eventType));
    setFormValidated(false);
  };

  //configurar un evento por primera vez
  const moveTo = () => {
    const eventId = event.list[event.list.length - 1].id;
    navigate(`new/${eventId}`);
  };

  useEffect(() => {
    setElementVirtual({ getBoundingClientRect: () => new DOMRect(x, y) });

    setForm({
      ...form,
      startDate: startDateSelected ? startDateSelected : new Date(),
    });
  }, [x, y, startDateSelected]);

  useEffect(() => {
    if (event.createSuccess) {
      moveTo();
      onClose();
      dispatch(resetEventMessageActionCreator());
    }
    if (event.isLoading) {
      setFormValidated(false);
    }
  }, [event]);

  const handleChangeDate = (item: DateObject) => {
    item._format = "DD/MM/YY";

    setForm({
      ...form,
      startDate: new Date(item.year, item.month.number - 1, item.day),
    });
  };

  useEffect(() => {
    setForm({ ...form, storeId: brand.currentStore?.id });
  }, [brand.currentStore]);

  useEffect(() => {
    if (!event.isLoading) {
      if (
        (form.eventName !== "" &&
          form.eventName !== " " &&
          form.eventName.length >= 1 &&
          form.startDate.getTime() > new Date().getTime() &&
          form.startDate.getHours() > 0 &&
          timer) ||
        (form.eventName !== "" &&
          form.eventName !== " " &&
          form.eventName.length >= 1 &&
          form.startDate.getTime() > new Date().getTime() &&
          form.startDate.getHours() == 0 &&
          form.startDate.getMinutes() > 0 &&
          timer)
      ) {
        setFormValidated(true);
        setFormSend({
          ...formSend,
          storeId: form.storeId,
          eventName: form.eventName,
          eventDateTimeRange: {
            startDate: timeZoneDate(convertDateUTC(form.startDate)),
            endDate: timeZoneDate(convertDateUTC(form.endDate)),
          },
        });
      } else {
        setFormValidated(false);
      }
    }
  }, [form]);

  const CustomInput = ({ openCalendar, value, handleValueChange }: any) => {
    return (
      <Input
        width="256px"
        height="40px"
        onFocus={openCalendar}
        value={value}
        onChange={handleValueChange}
        isTextCenter={true}
        placeholder={t("views.events.popup_top.placeholder")}
      />
    );
  };

  return (
    <div ref={setPopperElement} style={containerStyle} {...attributes.popper}>
      <div className="CreateEventWrapperTop">
        <div className="ContainerTitle">
          <label htmlFor={"name"} className="title-field">
            {t("views.events.popup_top.event_name")}
          </label>
          <div className="image-close-modal" onClick={handleCloseClick}>
            <SimpleX color="#000" width={20} height={20} />
          </div>
        </div>

        <form
          className="formCreateEventContainer"
          onSubmit={(e) => handleSubmit(e, EventType.broadcast)}
        >
          <div className="Container-input-top">
            <Input
              inputName={"name"}
              inputId={"name"}
              type={"text"}
              width={"392px"}
              height={"40px"}
              value={form.eventName}
              onChange={handleChange}
            />
          </div>
          <div className="Container-input-central">
            <div className="row-container-items">
              <div className="colum-item space-right">
                <span className="mb">
                  <DateIcon />
                  <label className="title-field">
                    {" "}
                    {t("views.events.popup_top.start_time")}
                  </label>
                </span>
                {startDateSelected ? (
                  <Input
                    width="256px"
                    height="40px"
                    value={dateFormat(form.startDate)}
                    isTextCenter={true}
                    isDisabled={true}
                  />
                ) : (
                  <DatePicker
                    arrow={false}
                    minDate={new Date()}
                    format="DD/MM/YY"
                    className="yellow custom-calendar "
                    weekDays={weekDays}
                    onChange={handleChangeDate}
                    render={<CustomInput />}
                  />
                )}
              </div>
              <div className="colum-item space-left">
                <span className="text-custom">
                  <TimeClock
                    width={30}
                    height={30}
                    fontSize={"25"}
                    color={"#343434"}
                  />
                  <label className="title-field">
                    {" "}
                    {t("views.events.popup_top.time")}
                  </label>
                </span>

                <InputTime
                  inputName={"startTime"}
                  inputId={"startTime"}
                  type={"time"}
                  width={"120px"}
                  height={"40px"}
                  onChange={handleChange}
                  isTextCenter={true}
                />
              </div>
            </div>
          </div>
          <div className="Container-button ">
            <Button
              width="393px"
              height="42px"
              type={"submit"}
              isDisabled={!formValidated}
            >
              <label htmlFor={"name"} className=" text-button">
                {t("views.events.popup_top.button_create")}
              </label>
            </Button>

            <div
              className={`event-test-text ${!formValidated && "test-disabled"}`}
              onClick={(e) => formValidated && handleSubmit(e, EventType.test)}
            >
              {t("views.events.popup_top.button_create_test")}
            </div>
          </div>
        </form>
      </div>
      <div className="arrow" ref={setArrowElement} style={styles.arrow} />
    </div>
  );
};

export default PopUpCreateEventTop;
