import React, { ChangeEvent, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify-icon/react";
import {
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components";
import Button, { ButtonStyle } from "../../components/Button/Button";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { EmptyPieChartWidget } from "../../components/FiraIcons";
import FiraNavBar from "../../components/FiraNavBar/FiraNavBar";
import Skeleton from "../../components/Skeleton/Skeleton";
import { orderingType } from "../../components/Table/Table";
import { usePopupAlert, AlertType } from "../../providers/AlertProvider";
import {
  cleanSuccessMessageActionCreator,
  getOwnerStoresActionCreator,
  setCurrentStoreActionCreator,
  updateOwnerStoreActionCreator,
} from "../../store/Brand/BrandActions";
import { FiraStore, StoreType } from "../../types/Stores.d";
import { RootState, StoreStatusType } from "../../utils/types.d";
import "./StoresView.scss";

enum OrderingType {
  status = "STATUS",
  descend = "DESCEND_ALPHABET",
  ascend = "ASCEND_ALPHABET",
}

interface StatusSelect {
  name: string;
  value: StatusSelectType;
  color: string;
}

export enum StatusSelectType {
  active = "ACTIVE",
  inactive = "DEACTIVATED",
}

let timerID: NodeJS.Timeout;

const StoresView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showAlert = usePopupAlert();

  const { authentication, brand } = useSelector((state: RootState) => state);
  const [storeList, setStoreList] = useState<FiraStore[]>([]);
  const [filterValue, setFilterValue] = useState<string>("");
  const orderingList = [
    {
      id: 1,
      name: t("views.stores.table.status"),
      value: OrderingType.status,
    },
    {
      id: 2,
      name: "A - Z",
      value: OrderingType.descend,
    },
    {
      id: 3,
      name: "Z - A",
      value: OrderingType.ascend,
    },
  ];
  const optionsStatus = [
    {
      name: t(`views.stores.status.active`),
      value: StatusSelectType.active,
      color: "#FFF29C",
    },
    {
      name: t("views.stores.status.deactivated"),
      value: StatusSelectType.inactive,
      color: "#F7F8F9",
    },
  ];
  const [selectedOption, setSelectedOption] = useState<StatusSelect>(
    optionsStatus[0]
  );
  const [selectedFilter, setSelectedFilter] = useState<orderingType>(
    orderingList[0]
  );

  const handleStoreClick = (store: FiraStore) => {
    navigate(`details/${store.id}`);
  };

  const handleCollectionFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setFilterValue(keyword);
    if (brand.list) {
      if (keyword !== "") {
        setStoreList(
          brand.list.filter((sto: FiraStore) => {
            return sto.name.toLowerCase().includes(keyword.toLowerCase());
          })
        );
      } else {
        setStoreList(brand.list);
      }
    }
  };

  const handleOrdering = (id: number) => {
    let selected = orderingList.filter((p) => {
      return p.id === id;
    });

    setSelectedFilter(selected[0]);
  };

  const handleSelectRowOption = (option: StatusSelect, id: string) => {
    const store = storeList.filter((store) => {
      return store.id === id;
    });
    const newStore = { ...store[0], state: option.value };

    dispatch(updateOwnerStoreActionCreator(id, newStore));
    setSelectedOption(option);
  };

  const handleSetCurrent = (store: FiraStore) => {
    navigate(`${store.id}`);
  };

  const handleCreate = () => {
    navigate("new");
  };

  const handleChangeContext = (store: FiraStore) => {
    authentication.sessionId &&
      dispatch(setCurrentStoreActionCreator(authentication.sessionId, store));
  };

  //Handle Soft Deleted Stores
  const handleStoresFiltering = () => {
    if (storeList !== undefined && storeList !== null && storeList.length > 0) {
      const onlyExistingStores = storeList.filter(
        (item: FiraStore) => item.state !== StoreStatusType.DELETED
      );
      setStoreList(onlyExistingStores);
    } else {
      return;
    }
  };

  const getStores = () => {
    if (authentication.user?.businessClientId) {
      dispatch(
        getOwnerStoresActionCreator(authentication.user.businessClientId)
      );
    }
  };

  useEffect(() => {
    if (!brand.isLoading) {
      if (brand.list && brand.list.length > 0) {
        setStoreList(brand.list);
        handleStoresFiltering();
      }
    }
  }, [brand, brand.isLoading]);

  useEffect(() => {
    setStoreList(brand.list);
  }, [brand.list]);

  useEffect(() => {
    if (brand.editSuccess) {
      getStores();

      timerID = setTimeout(() => {
        dispatch(cleanSuccessMessageActionCreator());
      }, 1000);
    }
  }, [brand.editSuccess]);

  useEffect(() => {
    if (brand.createSuccess) {
      showAlert(AlertType.success, t("views.stores.createSuccess"));
      timerID = setTimeout(() => {
        dispatch(cleanSuccessMessageActionCreator());
      }, 3000);
    }
  }, [brand.createSuccess]);

  useEffect(() => {
    if (brand.error) {
      showAlert(AlertType.error, brand.error.message);
    }
  }, [brand.error]);

  useEffect(() => {
    getStores();
  }, [authentication]);

  useEffect(() => {
    if (!brand.isLoading && brand.currentStore) {
      navigate("/home/dashboard");
    }
  }, [brand]);

  const options = (
    <>
      {/* <div>
        <Button
          buttonStyle={ButtonStyle.transparent}
          width="86px"
          height="32px"
        >
          <span className={"body-12-low-text"}>
            {t("views.stores.exportBtn")}
          </span>
        </Button>
      </div>
      <div style={{ marginLeft: "5px" }}>
        <Button
          buttonStyle={ButtonStyle.transparent}
          width="88px"
          height="32px"
        >
          <span className={"body-12-low-text"}>
            {t("views.stores.importBtn")}
          </span>
        </Button>
      </div> */}
      <div style={{ marginLeft: "5px" }} onClick={handleCreate}>
        <Button width="136px" height="32px">
          <span className={"body-12-low-text"}>
            {t("views.stores.createBtn")}
          </span>
        </Button>
      </div>
    </>
  );

  const headerColumns = [
    {
      name: "",
      size: "16px",
    },
    {
      name: t("views.stores.table.storeName"),
      size: "20%",
    },
    {
      name: t("views.stores.table.status"),
      size: "100px",
    },
    {
      name: t("views.stores.table.storeId"),
      size: "40%",
    },
    {
      name: "",
      size: "30%",
    },
    {
      name: "",
      size: "16px",
    },
  ];

  const placeholder = [
    { id: 1, name: "placeholder" },
    { id: 2, name: "placeholder" },
    { id: 3, name: "placeholder" },
    { id: 4, name: "placeholder" },
    { id: 5, name: "placeholder" },
  ];

  const SkeletonTableBody = placeholder.map((data) => {
    return (
      <TableRow key={data.id}>
        <TableCell size="16px"></TableCell>
        <TableCell size="20%">
          <div>
            <Skeleton width="90%" height="22px" />
          </div>
        </TableCell>
        <TableCell size="100px" centerContent>
          <div className="centerSkeleton">
            <Skeleton width="88px" height="22px" />
          </div>
        </TableCell>
        <TableCell size="30%" centerContent>
          <div className="centerSkeleton">
            <Skeleton width="90%" height="22px" />
          </div>
        </TableCell>
        <TableCell RightContent>
          <div
            style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}
          >
            <Skeleton width="80px" height="22px" />
            <Skeleton width="80px" height="22px" />
          </div>
        </TableCell>
        <TableCell size="16px"></TableCell>
      </TableRow>
    );
  });

  const header = (
    <TableRow>
      {headerColumns.map((item, i) => {
        return (
          <TableHead key={i} size={item.size} centerContent={i === 2}>
            {item.name}
          </TableHead>
        );
      })}
    </TableRow>
  );

  const body = (
    <>
      {storeList.map((store, i) => {
        return (
          <TableRow key={i}>
            <TableCell size="16px">
              <div
                style={{
                  padding: "0px 10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {store.storeType === StoreType.MAIN ? (
                  <Icon
                    icon={"solar:star-bold"}
                    style={{ color: "#ffde07", fontSize: 24 }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </TableCell>
            <TableCell size="20%">
              <p className={`body-14-text ellipsis`}>{store.name}</p>
            </TableCell>
            <TableCell size="100px" centerContent>
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {store.storeType !== StoreType.MAIN && (
                  <CustomSelect
                    key={i}
                    width="80px"
                    height="22px"
                    options={
                      store.state === StatusSelectType.active
                        ? [optionsStatus[1]]
                        : [optionsStatus[0]]
                    }
                    value={
                      store.state === StatusSelectType.inactive
                        ? optionsStatus[1].name
                        : optionsStatus[0].name
                    }
                    handleSelected={handleSelectRowOption}
                    // icon={<ArrowDownFill fontSize={"9px"} />}
                    backgroundColor={
                      store.state === StatusSelectType.inactive
                        ? optionsStatus[1].color
                        : optionsStatus[0].color
                    }
                    padding={"0px 6px"}
                    selectId={store.id}
                  />
                )}
              </div>
            </TableCell>
            <TableCell size="40%">
              <p className={`body-14-text ellipsis`}>{store.id}</p>
            </TableCell>
            <TableCell RightContent flexRow size="20%">
              {store.state === StatusSelectType.active && (
                <>
                  <Button
                    buttonStyle={ButtonStyle.transparent}
                    width={"80px"}
                    clickHandler={() => handleSetCurrent(store)}
                  >
                    <span className={"StoreTableLink"}>
                      {t("views.stores.table.detailsBtn")}
                    </span>
                  </Button>
                  <Button
                    buttonStyle={ButtonStyle.transparent}
                    width={"80px"}
                    clickHandler={() => handleChangeContext(store)}
                  >
                    <span className={"StoreTableLink"}>
                      {t("views.stores.table.accessBtn")}
                    </span>
                  </Button>
                </>
              )}
            </TableCell>
            <TableCell size="16px"></TableCell>
          </TableRow>
        );
      })}
    </>
  );

  const EmptyState = () => {
    return (
      <div className={"TableEmptyState"}>
        <EmptyPieChartWidget />
      </div>
    );
  };

  return (
    <>
      <FiraNavBar
        titleSection={t("views.stores.title")}
        trailingOptions={options}
      />
      <div style={NoteWrapper}>
        <Trans i18nKey={"tips.main_store"}>
          <span>
            <strong>Nota:</strong> La tienda marcada con una ⭐ es la que se
            utilizara para eventos simultáneos y masivos.
          </span>
        </Trans>
      </div>
      <TableHeader
        hideCheckBox={true}
        // orderingList={orderingList}
        // orderingValue={selectedFilter}
        // orderingClick={handleOrdering}
        searchInputValue={filterValue}
        searchInputChangeHandle={handleCollectionFiltering}
      />
      {storeList.length === 0 ? (
        <EmptyState />
      ) : (
        <Table
          header={header}
          body={brand.isLoading ? SkeletonTableBody : body}
        />
      )}
    </>
  );
};

const NoteWrapper: React.CSSProperties = {
  padding: "10px 16px",
  margin: "6px 0px",
  borderRadius: 10,
  fontSize: 12,
  backgroundColor: "#fff",
};

export default StoresView;
