import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { editStoreNameActionCreator } from "../../store/Brand/BrandActions";

import { RootState } from "../../store/Store";
import Button, { ButtonStyle } from "../Button/Button";
import Input, { InputStyles } from "../Input/Input";

const MainStoreSettings: React.FC = () => {
  const { brand } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [name, setName] = useState("");

  const onInputChange = (value: React.ChangeEvent<HTMLInputElement>) => {
    value.preventDefault();
    setName(value.target.value);
  };

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (brand.currentStore) {
      const { id } = brand.currentStore;

      dispatch(editStoreNameActionCreator(id, name));
    }
  };

  const isDisabled = (): boolean => {
    return name === brand.currentStore?.name;
  };

  useEffect(() => {
    setName(brand.currentStore ? brand.currentStore.name : "");
  }, []);

  return (
    <div style={SettingsWrapper}>
      <div style={Header}>
        <span style={Title}>
          {t("components.store_settings.general_info.title")}
        </span>
      </div>
      <form style={FormWrapper} onSubmit={onSubmit}>
        <div style={FormGroup}>
          <label style={FormLabel} htmlFor="name">
            {t("components.store_settings.general_info.store_name")}*
          </label>
          <Input
            width="100%"
            height="40px"
            borderRadius="4px"
            inputName={"name"}
            value={name}
            placeholder={t("components.store_settings.general_info.store_name")}
            onChange={onInputChange}
            inputStyle={InputStyles.profile}
          />
        </div>
        <div style={ButtonWrapper}>
          <Button buttonStyle={ButtonStyle.border} type="button">
            {t("buttons.cancel")}
          </Button>
          <Button isDisabled={isDisabled()}>{t("buttons.save")}</Button>
        </div>
      </form>
    </div>
  );
};

const SettingsWrapper: React.CSSProperties = {
  backgroundColor: "#fff",
  height: "100%",
  borderRadius: 8,
};

const Header: React.CSSProperties = {
  padding: "10px 24px",
  borderBottom: "1px solid #D5DDE0",
};

const Title: React.CSSProperties = {
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px",
};

const FormWrapper: React.CSSProperties = {
  padding: 24,
  display: "grid",
  gridTemplateRows: "1fr auto",
};

const FormGroup: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  gap: 8,
};

const FormLabel: React.CSSProperties = {
  fontSize: 14,
  fontWeight: 500,
};

const ButtonWrapper: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 8,
  paddingTop: 24,
};

export default MainStoreSettings;
