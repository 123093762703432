import { useTranslation } from "react-i18next";
import { createContext, ReactNode, useEffect, useState } from "react";
import EventGeneralDetails from "../../components/EventConfiguration/EventGeneralDetails/EventGeneralDetails";
import EventTeam from "../../components/EventConfiguration/EventTeam/EventTeam";
import EventProducts from "../../components/EventConfiguration/EventProducts/EventProducts";
import EventColor from "../../components/EventConfiguration/EventColor/EventColor";
import EventLogin from "../../components/EventConfiguration/EventLogin/EventLogin";
import EventAttachments from "../../components/EventConfiguration/EventAttachments/EventAttachments";
import EventTemplates from "../../components/EventConfiguration/EventTemplates/EventTemplates";
import FiraStepper, {
  EVENT_ROUTES,
  FiraStep,
} from "../../components/FiraStepper/FiraStepper";
import {
  handleActiveRoutes,
  inactivateAllSteps,
  limiterMax,
  limiterMin,
} from "../../utils/stepperNavigation";
import { checkConfigLocation } from "../../utils/checkConfigLocation";
import Button, { ButtonStyle } from "../../components/Button/Button";
import {
  Category,
  EventGeneralDetailsType,
  EventSetUpConfiguration,
  FiraConfiguration,
  FiraProduct,
  FiraUserModerator,
  RoleType,
  TemplatesResponse,
} from "../../utils/types.d";
import SvgStar from "../../components/FiraIcons/Star";
import styles from "./EventConfigurationView.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import {
  EditFiraEventOptimizedActionCreator,
  FirstSettingUpOptimizedActionCreator,
  GetCurrentEventProductsActionCreator,
  getEventActionCreator,
  resetCurrentEventActionCreator,
  resetCurrentProductsActionCreator,
  resetEditEventActionCreator,
  resetUpdateEventActionCreator,
} from "../../store/Event/EventActions";
import { useNavigate, useParams } from "react-router-dom";
import { MainLoader } from "../../components";
import { getBusinessStoreByIdService } from "../../services/store";
import { setCurrentStoreActionCreator } from "../../store/Brand/BrandActions";
import {
  EditEventRequestDTO,
  FiraFiles,
  FiraFirstSettingUpConfiguration,
} from "../../types/Events";
import {
  changeSuperAdmin,
  compareModerators,
  compareProducts,
  eventDetailsEditInitialState,
  FiraEventDetailsFormData,
  firaWebConfigurationInitialState,
  formatCurrentFiraProducts,
  formatFiraProductsAsStrings,
  handleModeratorsEdition,
  handleProductsEdition,
  loginFormHandler,
} from "./Utils/DataHandlers";
import { AlertType, usePopupAlert } from "../../providers/AlertProvider";
import { EventStatus } from "../../utils/enums";
import { BadWordsType } from "../../components/EventCreation/EventAttachments/EventAttachments";
import EventBuyButton from "../../components/EventConfiguration/EventBuyButton/EventBuyButton";
import EventBroadcastingResolution from "../../components/EventConfiguration/EventBroadcastingResolution/EventBroadcastingResolution";
import { StoreType } from "../../types/Stores.d";

export enum EventConfig {
  NEW = "NEW",
  EDIT = "EDIT",
}

const StepsInitialSteps: FiraStep[] = [
  {
    title: "",
    route: "",
    active: false,
    component: <></>,
    info: {
      title: "",
      desc: "",
    },
  },
];

const eventFormInitialState: EventSetUpConfiguration = {
  id: "",
  firaWebConfiguration: firaWebConfigurationInitialState,
  products: [],
  moderatorList: [],
  badWordsType: BadWordsType.default,
  isGlobal: true,
};

const eventDetailsInitialState: EventGeneralDetailsType = {
  broadcastingId: "",
  eventName: "",
  scheduleDate: new Date(),
  categories: [],
};

export const EventConfigurationContext = createContext<any>(
  eventFormInitialState
);

const EventConfigurationView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { event, authentication, brand } = useSelector(
    (state: RootState) => state
  );
  const { id } = useParams();
  const showAlert = usePopupAlert();
  const [availableCurrentEvent, setAvailableCurrentEvent] =
    useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentMiniStep, setCurrentMiniStep] = useState<number>(0);
  const [usingMiniSteps, setUsingMiniSteps] = useState<boolean>(false);
  const [configType, setConfigType] = useState<EventConfig>();
  const [bottomMessage, setBottomMessage] = useState<ReactNode>();
  const [miniSteps, setMiniSteps] = useState<FiraStep[]>(StepsInitialSteps);
  const [steps, setSteps] = useState<FiraStep[]>(StepsInitialSteps);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [eventForm, setEventForm] = useState<EventSetUpConfiguration>(
    eventFormInitialState
  );
  const [eventDetails, setEventDetails] = useState<EventGeneralDetailsType>(
    eventDetailsInitialState
  );
  const [eventProducts, setEventProducts] = useState<string[]>();
  const [errorMessageIsOpen, setErrorMessageIsOpen] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [validUrl, setValidUrl] = useState<boolean>(false);
  const urlRegex = new RegExp(
    /((([A-Za-z]{3,9}:(?:\/\/))(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)(\.){1,}[a-zA-Z0-9()]*\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=])*/gi
  );
  const [buyButtonSaved, setBuyButtonSaved] = useState<boolean>(false);
  const currentDateTime = new Date();

  /**
   * By Config Type
   ** NEW **
   * Fira First Setting Up - When the event is NEW or doesn't have a fira web configuration, holds info of moderators,
   * products, firawebconfiguration and bad words
   *
   ** EDIT **
   * Fira Event Edit Details - Holds data from Event Details Screen, including Teaser, Preview and Categories
   * uses "/v1/live-broadcast-event/edit" - must be sent as a multipart using the data handler
   * Fira Event Products - Each product must be added or removed individually -
   * uses `/v1/live-broadcast-event/broadcast-product/add` and `/v1/live-broadcast-event/broadcast-product/delete/${eventId}/${productId}`
   * Fira Event Moderators - Each moderator must be added or removed individually - uses `/v1/live-broadcast-event/moderator/delete` and `/v1/live-broadcast-event/moderator/add`
   * Fira Web Configuration - uses "/v1/live-broadcast-event/configuration/edit"
   * Fira Event Categories - Categories from current event
   *
   *
   ** EDIT & NEW **
   * Fira Web Configuration - Holds data from color, login & url
   */
  const [firaEventEditDetails, setFiraEventEditDetails] =
    useState<EditEventRequestDTO>(eventDetailsEditInitialState);
  const [firaFirstSettingUp, setFiraFirstSettingUp] =
    useState<FiraFirstSettingUpConfiguration>();
  const [firaEventCategories, setFiraEventCategories] = useState<Category[]>(
    []
  );
  const [firaFiles, setFiraFiles] = useState<FiraFiles>({
    eventTeaserName: "",
    eventTeaserFile: null,
    eventImageName: "",
    eventImageFile: null,
  });
  const [firaEventModerators, setFiraEventModerators] = useState<
    FiraUserModerator[]
  >([]);
  const [moderatorsListIsLoading, setModeratorsListIsLoading] = useState(false);
  const [firaEventProducts, setFiraEventProducts] = useState<FiraProduct[]>([]);
  const [firaWebConfiguration, setFiraWebConfiguration] =
    useState<FiraConfiguration>(firaWebConfigurationInitialState);
  const [usingTemplate, setUsingTemplate] = useState<boolean>(false);
  const currentTime = new Date();
  /**
   * Navigation Array
   ** Builds the object that will generate de steps for navigation,
   ** if you add another object inside the array, it will generate another step automatically.
   ** make sure to check if it's a ministep or a mainstep and what component it leads to.
   */
  const mainStore = brand.list.find((item) => item.storeType === "MAIN_STORE");
  const useOwnerDefaultConfig = brand.currentStore?.useOwnerDefaultConfig;
  const MINI_STEPS = [
    {
      title: t("event_creation.params.interfaceTitle"),
      route:
        configType === EventConfig.NEW
          ? EVENT_ROUTES.TEMPLATES
          : EVENT_ROUTES.COLOR,
      active: false,
      component:
        configType === EventConfig.NEW ? <EventTemplates /> : <EventColor />,
      info: {
        title:
          configType === EventConfig.NEW
            ? t("event_creation.params.interfaceTitle")
            : t("event_creation.params.colorSelectionTitle"),
        desc:
          configType === EventConfig.NEW
            ? t("event_creation.params.interfaceMessage")
            : t("event_creation.params.colorSelectionMessage"),
      },
    },
    {
      title: t("event_creation.params.loginParamsTitle"),
      route: EVENT_ROUTES.LOGIN,
      active: false,
      component: <EventLogin />,
      info: {
        title: t("event_creation.params.loginParamsTitle"),
        desc: t("event_creation.params.loginParamsMessage"),
      },
    },
    {
      title: t("event_creation.params.buyButtonTitle"),
      route: EVENT_ROUTES.BUY_BUTTON,
      active: false,
      component: <EventBuyButton />,
      info: {
        title: t("event_creation.params.buyButtonTitle"),
        desc: t("event_creation.params.buyButtonMessage"),
      },
    },
    {
      title: t("event_creation.params.buyButtonTitle"),
      route: EVENT_ROUTES.ORIENTATION,
      active: false,
      component: <EventBroadcastingResolution />,
      info: {
        title: t("event_creation.params.broadcastingResolution.title"),
        desc: t("event_creation.params.broadcastingResolution.subtitle"),
      },
    },
  ];

  const STEPS_OWNER = [
    {
      title: t("event_creation.generalRoute"),
      route: EVENT_ROUTES.GENERAL,
      active: true,
      component: <EventGeneralDetails />,
      info: {
        title: t("event_creation.general_details.title"),
        desc: t("event_creation.general_details.message"),
      },
    },

    {
      title: t("event_creation.productsRoute"),
      route: EVENT_ROUTES.PRODUCTS,
      active: false,
      component: <EventProducts />,
      info: {
        title: "",
        desc: "",
      },
    },
  ];

  const STEPS = [
    {
      title: t("event_creation.generalRoute"),
      route: EVENT_ROUTES.GENERAL,
      active: true,
      component: <EventGeneralDetails />,
      info: {
        title: t("event_creation.general_details.title"),
        desc: t("event_creation.general_details.message"),
      },
    },
    {
      title: t("event_creation.teamRoute"),
      route: EVENT_ROUTES.TEAM,
      active: false,
      component: <EventTeam />,
      info: {
        title: t("event_creation.team.title"),
        desc: t("event_creation.team.title"),
      },
    },
    {
      title: t("event_creation.productsRoute"),
      route: EVENT_ROUTES.PRODUCTS,
      active: false,
      component: <EventProducts />,
      info: {
        title: "",
        desc: "",
      },
    },
    {
      title: t("event_creation.paramsRoute"),
      route: EVENT_ROUTES.PARAMS,
      active: false,
      component: <></>,
      info: {
        title: "",
        desc: "",
      },
    }, //Este es sólo demostrativo - el correspondiente real de los ministeps es el de abajo.
    {
      title: t("event_creation.live"),
      route: EVENT_ROUTES.CREATE_LIVE,
      active: false,
      component: <></>,
      ministeps: MINI_STEPS,
      info: {
        title: "",
        desc: "",
      },
    },
  ];

  const renderPage = () => {
    if (!usingMiniSteps) {
      return steps[currentPage].component;
    } else {
      return miniSteps[currentMiniStep].component;
    }
  };

  const getPageInfo = () => {
    if (!usingMiniSteps) {
      if (steps[currentPage])
        return (
          (steps[currentPage] && steps[currentPage].info) || {
            title: "",
            desc: "",
          }
        );
    } else {
      return (
        (miniSteps[currentMiniStep] && miniSteps[currentMiniStep].info) || {
          title: "",
          desc: "",
        }
      );
    }
  };

  //Init
  const handleConfigType = () => {
    if (event.currentEvent)
      if (
        checkConfigLocation() === "NEW" ||
        event.currentEvent.firaWebConfiguration === null ||
        event.currentEvent.firaWebConfiguration === undefined
      ) {
        setConfigType(EventConfig.NEW);
      } else {
        setConfigType(EventConfig.EDIT);
      }

    id && setEventForm({ ...eventForm, id: id });
  };

  const handleStepsData = () => {
    if (
      authentication.isAccountOwner &&
      (brand.currentStore?.storeType === "MAIN_STORE" || !brand.currentStore) &&
      event.currentEvent?.isParent
    ) {
      setSteps(STEPS_OWNER);
    } else {
      setSteps(STEPS), setMiniSteps(MINI_STEPS);
    }

    setIsLoading(false);
  };

  const handleBuyButtonValidation = () => {
    if (
      miniSteps[currentMiniStep].route === EVENT_ROUTES.BUY_BUTTON &&
      !buyButtonSaved &&
      firaWebConfiguration.buyButtonText !== null
    ) {
      if (event.currentEvent?.firaWebConfiguration !== null) {
        return (
          firaWebConfiguration.buyButtonText !==
          event.currentEvent?.firaWebConfiguration?.buyButtonText
        );
      }
      return true;
    }
    return false;
  };

  /** Navigation starts here */
  const getNextButtonType = () => {
    return readyToSubmit()
      ? t("event_creation.save")
      : t("event_creation.nextButton");
  };

  const navigateNext = () => {
    if (usingMiniSteps) {
      if (handleBuyButtonValidation()) {
        showAlert(AlertType.error, t("event_creation.params.noSavedAlert"));
        return;
      }
      if (limiterMax(miniSteps, currentMiniStep)) {
        return;
      } else {
        let next = currentMiniStep + 1;
        setCurrentMiniStep(next);
      }
    } else {
      let next = currentPage + 1;
      setCurrentPage(next);
    }
  };

  const navigatePrev = () => {
    if (usingMiniSteps) {
      if (currentMiniStep === 0) {
        setUsingMiniSteps(false);
        setCurrentPage(2);
        handleMiniSteps("INACTIVATE");
      } else {
        let prev = currentMiniStep - 1;
        setCurrentMiniStep(prev);
      }
    } else {
      if (limiterMin(currentPage)) {
        return;
      } else {
        let prev = currentPage - 1;
        setCurrentPage(prev);
      }
    }
  };

  const handleRoutes = () => {
    if (!usingMiniSteps) {
      setSteps(handleActiveRoutes(steps, [0, currentPage]));
    } else {
      //handleMiniSteps();
    }
  };

  const handleMiniSteps = (action?: string) => {
    let updatedMiniSteps: FiraStep[] = [];
    if (action === "INACTIVATE") {
      updatedMiniSteps = inactivateAllSteps(miniSteps);
    } else {
      updatedMiniSteps = handleActiveRoutes(miniSteps, [0, currentMiniStep]);
    }

    setMiniSteps(updatedMiniSteps);
    const updatedSteps = [...steps];
    const stepIndexToUpdate = updatedSteps.findIndex(
      (step) => step.route === EVENT_ROUTES.CREATE_LIVE
    );

    if (stepIndexToUpdate !== -1) {
      updatedSteps[stepIndexToUpdate].ministeps = updatedMiniSteps;
      setSteps(updatedSteps);
    }
  };

  /** Navigation ends here */
  const handleDetails = (details: EventGeneralDetailsType) => {
    setEventDetails(details);
  };

  const handleChange = (form: EventSetUpConfiguration) => {
    setEventForm(form);
  };

  const handleProductsSubmit = async () => {
    return new Promise<void>((resolve) => {
      if (eventProducts && eventProducts.length > 0) {
        setEventForm({ ...eventForm, products: eventProducts });
      }
      resolve();
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setShowLoader(true);
    await handleProductsSubmit();
    if (
      configType === EventConfig.NEW &&
      event.currentEvent?.firaWebConfiguration === null
    ) {
      handleFirstSettingUp();
    } else {
      await handleEdit();
    }
  };

  const readyToSubmit = () => {
    if (firaWebConfiguration.personalDataUrl === "") {
      setFiraWebConfiguration({
        ...firaWebConfiguration,
        personalDataUrl:
          "https://www.firaonlive.com/TratamiendoDeDatosPersonales",
      });
    }
    if (
      (useOwnerDefaultConfig === true &&
        steps[currentPage].route === EVENT_ROUTES.PRODUCTS) ||
      (authentication.isAccountOwner && // caso creacion de evento desde cuenta owner
        steps[currentPage].route === EVENT_ROUTES.PRODUCTS &&
        brand.currentStore === undefined) ||
      (authentication.isAccountOwner && // caso creacion de evento desde cuenta owner
        steps[currentPage].route === EVENT_ROUTES.PRODUCTS &&
        brand.currentStore?.storeType === StoreType.MAIN)
    ) {
      return true;
    } else if (usingMiniSteps)
      return (
        miniSteps[currentMiniStep].route === EVENT_ROUTES.ORIENTATION ||
        (miniSteps[currentMiniStep].route === EVENT_ROUTES.TEMPLATES &&
          usingTemplate) ||
        event.currentEvent?.status === "DONE"
      );
  };

  const handleFirstSettingUp = () => {
    if (event.currentEvent && firaWebConfiguration) {
      let body: EventSetUpConfiguration = {
        id: event.currentEvent.id,
        firaWebConfiguration: useOwnerDefaultConfig
          ? null
          : loginFormHandler(firaWebConfiguration),
        products: formatFiraProductsAsStrings(firaEventProducts),
        moderatorList: authentication.redirectedFromFiraBusiness
          ? changeSuperAdmin(firaEventModerators)
          : firaEventModerators,
        badWordsType: BadWordsType.default,
        isGlobal: true,
      };
      let res = FiraEventDetailsFormData(firaEventEditDetails, firaFiles);
      dispatch(FirstSettingUpOptimizedActionCreator(body, res));
    }
  };

  const getModerators = () => {
    let superMod: any;
    if (authentication.user) {
      superMod = {
        id: authentication.user?.id,
        email: authentication.user.email,
        role: RoleType.ACCOUNT_OWNER,
        userName: authentication.user.username,
        firstName: authentication.user.firstName,
        lastName: authentication.user.lastName || "",
        avatar: "",
        username: authentication.user.username,
      };

      setFiraEventModerators([superMod]);
    }
  };

  const handleEdit = async () => {
    if (
      event.currentEvent &&
      firaEventModerators &&
      event.currentEvent.status !== "DONE"
    ) {
      const modLists = compareModerators(
        event.currentEvent.moderators,
        firaEventModerators
      );
      if (modLists) {
        await handleModeratorsEdition(
          modLists.added,
          modLists.removed,
          event.currentEvent.id
        );
      }
    }
    if (
      event.currentEventProducts &&
      firaEventProducts &&
      event.currentEvent &&
      event.currentEvent.status !== "DONE"
    ) {
      const productsList = compareProducts(
        event.currentEventProducts,
        firaEventProducts
      );
      if (productsList) {
        handleProductsEdition(
          productsList.added,
          productsList.removed,
          event.currentEvent.id,
          event.currentEventProducts
        );
      }
    }

    let res = FiraEventDetailsFormData(firaEventEditDetails, firaFiles);

    if (res && event.currentEvent && firaWebConfiguration) {
      dispatch(
        EditFiraEventOptimizedActionCreator(
          res,
          loginFormHandler(firaWebConfiguration),
          event.currentEvent.id,
          event.currentEvent.status
        )
      );
    }
  };

  const getEventStore = async (sessionid: string, id: string) => {
    try {
      const response = await getBusinessStoreByIdService(id);

      dispatch(setCurrentStoreActionCreator(sessionid, response.data));
    } catch (err) {
      console.error(err);
    }
  };

  const getEventProducts = (eventId: string, eventStatus: EventStatus) => {
    if (eventId && eventStatus) {
      dispatch(GetCurrentEventProductsActionCreator(eventId, eventStatus));
    }
  };

  const handelValidUrl = () => {
    return (
      miniSteps[currentMiniStep].route === EVENT_ROUTES.LOGIN &&
      firaWebConfiguration.enableLogin &&
      firaWebConfiguration.disclaimerUrl !== undefined &&
      !urlRegex.test(firaWebConfiguration.disclaimerUrl)
    );
  };

  const useCustomConfig = () => {
    const updatedMiniSteps = [...miniSteps];
    updatedMiniSteps[currentMiniStep].component = <EventColor />;
    updatedMiniSteps[currentMiniStep].route = EVENT_ROUTES.COLOR;
    setMiniSteps(updatedMiniSteps);
  };

  const useTemplate = (template: TemplatesResponse) => {
    if (template !== null) {
      setUsingTemplate(true);
      setFiraWebConfiguration(template.firaWebConfiguration);
    } else {
      setFiraWebConfiguration(firaWebConfigurationInitialState);
      setUsingTemplate(false);
    }
  };

  useEffect(() => {
    if (event.currentEventProducts) {
      setFiraEventProducts(
        formatCurrentFiraProducts(event.currentEventProducts)
      );
    }
  }, [event.currentEventProducts]);

  useEffect(() => {
    handleRoutes();
    if (steps[currentPage].route === EVENT_ROUTES.PARAMS) {
      setUsingMiniSteps(true);
    }
  }, [currentPage]);

  useEffect(() => {
    if (usingMiniSteps) {
      handleMiniSteps();
    }
  }, [usingMiniSteps]);

  useEffect(() => {
    handleRoutes();
  }, [currentMiniStep]);

  useEffect(() => {
    handleStepsData();
  }, [configType, brand.currentStore]);

  useEffect(() => {
    event.currentEvent &&
      dispatch(getEventActionCreator(event.currentEvent.id));
    handleStepsData();
    if (event.currentEvent && authentication.isAccountOwner) {
      authentication.sessionId &&
        brand.currentStore !== null &&
        brand.currentStore !== undefined &&
        getEventStore(authentication.sessionId, brand.currentStore.id);
    }
  }, [event.createSuccess]);

  useEffect(() => {
    if (event.updatedEvent || event.editedEvent || event.updatedEvent) {
      navigate(-1);
    } else if (
      (!event.updatedEvent || !event.editedEvent) &&
      event.error != undefined
    ) {
      setErrorMessageIsOpen(true);
    }
  }, [event]);

  useEffect(() => {
    eventProducts && setEventForm({ ...eventForm, products: eventProducts });
  }, [eventProducts]);

  useEffect(() => {
    setBottomMessage(
      <div
        className={styles.EventConfigurationComponentsContainer__BottomMessage}
      >
        <SvgStar />
        <span>{t("event_creation.team.info")}</span>
      </div>
    );

    //Init
    handleRoutes();

    dispatch(resetEditEventActionCreator());
    dispatch(resetUpdateEventActionCreator());

    return () => {
      dispatch(resetCurrentProductsActionCreator());
      dispatch(resetCurrentEventActionCreator());
    };
  }, []);

  //Data init
  useEffect(() => {
    if (event.currentEvent) {
      setAvailableCurrentEvent(true);
      handleConfigType();
      setFiraEventEditDetails({
        broadcastingId: event.currentEvent ? event.currentEvent.id : "",
        eventName: event.currentEvent ? event.currentEvent.eventName : "",
        scheduleDate: event.currentEvent
          ? event.currentEvent.scheduledDate
          : new Date(),
        eventDescription:
          event.currentEvent && event.currentEvent.eventDescription
            ? event.currentEvent.eventDescription
            : "",
        categories: [],
        isGlobal:
          event.currentEvent.isGlobal !== null &&
          event.currentEvent.isGlobal !== undefined
            ? event.currentEvent.isGlobal
            : true,
      });
      if (configType === EventConfig.EDIT) {
        setFiraEventCategories(
          event.currentEvent && event.currentEvent.firaEventCategory
            ? event.currentEvent.firaEventCategory
            : []
        );
        setFiraFiles({
          ...firaFiles,
          eventImageName: event.currentEvent.eventPreviewImage
            ? event.currentEvent.eventPreviewImage
            : "",
          eventTeaserName: event.currentEvent.eventTeaser
            ? event.currentEvent.eventTeaser
            : "",
        });
        setFiraEventModerators(
          event.currentEvent.moderators ? event.currentEvent.moderators : []
        );
        getEventProducts(event.currentEvent.id, event.currentEvent.status);
        if (event.currentEvent.firaWebConfiguration !== null) {
          setFiraWebConfiguration(event.currentEvent.firaWebConfiguration);
        }
      }
    }

    if (event.createEventResponse) {
      setAvailableCurrentEvent(true);
      handleConfigType();
      getModerators();
      setFiraEventEditDetails({
        broadcastingId: event.currentEvent ? event.currentEvent.id : "",
        eventName: event.currentEvent ? event.currentEvent.eventName : "",
        scheduleDate: event.currentEvent
          ? new Date(event.currentEvent.startDate)
          : new Date(),
        eventDescription: "",
        categories: [],
        isGlobal:
          event.currentEvent &&
          event.currentEvent.isGlobal !== null &&
          event.currentEvent.isGlobal !== undefined
            ? event.currentEvent.isGlobal
            : true,
      });
    } else if (event.currentEvent === undefined) {
      setAvailableCurrentEvent(false);
      showAlert(
        AlertType.error,
        "No hay ningún evento seleccionado. Por favor intente de nuevo"
      );
    }
  }, [event.currentEvent, configType]);

  useEffect(() => {
    if (firaEventCategories && firaEventCategories.length > 0) {
      let categories: string[] = [];
      firaEventCategories.map((obj) => {
        categories.push(obj.description);
      });

      setFiraEventEditDetails({
        ...firaEventEditDetails,
        categories: categories,
      });
    }
  }, [firaEventCategories]);

  const isValid = () => {
    let templatesValidation =
      usingMiniSteps &&
      miniSteps[currentMiniStep].route === EVENT_ROUTES.TEMPLATES &&
      !usingTemplate;

    return (
      isLoading ||
      handelValidUrl() ||
      !availableCurrentEvent ||
      moderatorsListIsLoading ||
      templatesValidation ||
      firaEventEditDetails.scheduleDate < currentDateTime
    );
  };

  return (
    <>
      {showLoader && <MainLoader />}
      <div className={styles.EventConfigurationWizardWrapper}>
        <div className={styles.EventConfigurationTop}>
          <FiraStepper steps={steps} showMiniSteps={false} />
        </div>
        <div className={styles.EventConfigurationComponentsContainer}>
          {availableCurrentEvent && (
            <>
              {currentPage !== 2 && (
                <div
                  className={styles.EventConfigurationComponentsContainer__Info}
                >
                  <h3 className={styles.Title}>{getPageInfo()?.title || ""}</h3>
                  <span className={styles.Desc}>
                    {getPageInfo()?.desc || ""}
                  </span>
                </div>
              )}

              <div
                className={`${
                  styles.EventConfigurationComponentsContainer__Component
                } ${currentPage === 2 ? styles.NoPadding : ""}`}
              >
                <EventConfigurationContext.Provider
                  value={{
                    eventForm,
                    eventDetails,
                    handleChange,
                    handleDetails,
                    eventProducts,
                    setEventProducts,
                    validUrl,
                    setValidUrl,
                    configType,
                    firaEventEditDetails,
                    setFiraEventEditDetails,
                    firaFirstSettingUp,
                    setFiraFirstSettingUp,
                    firaEventCategories,
                    setFiraEventCategories,
                    firaFiles,
                    setFiraFiles,
                    firaEventModerators,
                    setFiraEventModerators,
                    setModeratorsListIsLoading,
                    firaEventProducts,
                    setFiraEventProducts,
                    firaWebConfiguration,
                    setFiraWebConfiguration,
                    useCustomConfig,
                    useTemplate,
                    setBuyButtonSaved,
                    buyButtonSaved,
                  }}
                >
                  {!isLoading && renderPage()}
                </EventConfigurationContext.Provider>
              </div>
              {brand.currentStore !== undefined &&
                steps[currentPage].route === EVENT_ROUTES.TEAM &&
                bottomMessage}
            </>
          )}
        </div>
        <div className={styles.EventConfigurationBottom}>
          <div style={currentPage === 0 ? { visibility: "hidden" } : {}}>
            <Button
              height="43px"
              width="176px"
              buttonStyle={ButtonStyle.border}
              clickHandler={navigatePrev}
              isDisabled={isLoading}
            >
              <span className={styles.EventConfigurationBottom__Button}>
                {t("event_creation.prevButton")}
              </span>
            </Button>
          </div>

          <Button
            height="43px"
            width="176px"
            clickHandler={readyToSubmit() ? handleSubmit : navigateNext}
            isDisabled={isValid()}
          >
            <span className={styles.EventConfigurationBottom__Button}>
              {getNextButtonType()}
            </span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default EventConfigurationView;
