import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { refreshTokenService } from "../../services/auth";
import { handleError, RequestError } from "../../services/errorHandling";
import {
  createStoreService,
  getUserStoresService,
  editStoreService,
  getStoreBy,
  editStore,
  ownerStoreList,
  updateOwnerStore,
  createOwnerStoreService,
  editStoreName,
} from "../../services/store";
import { uploadImageService } from "../../services/upload";
import {
  UserStoresResponse,
  FiraOwnerTeamMemberResponse,
} from "../../types/Response";
import { FiraStore } from "../../types/Stores";
import { CreateStoreResponse } from "../../utils/response";
import {
  CreateStoreForm,
  EditStoreProfileForm,
  Ecommerce,
  CreateOwnerStoreForm,
} from "../../utils/types";
import { AuthActions, BrandActions } from "../actionsContants";
import { LogoutActionType, RefreshTokenActionType } from "../Auth/AuthActions";

interface GetUserStoresActionType {
  type:
    | BrandActions.getUserStoresLoading
    | BrandActions.getUserStoresSuccess
    | BrandActions.getUserStoresFailed;
  payload?: UserStoresResponse[] | RequestError;
}
export const getUserStoresActionCreator = () => {
  return async (
    dispatch: ThunkDispatch<
      GetUserStoresActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: BrandActions.getUserStoresLoading });
      const response = await getUserStoresService();
      dispatch({
        type: BrandActions.getUserStoresSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: BrandActions.getUserStoresFailed,
        payload: handleError(error),
      });
    }
  };
};

interface SetIsNewUserActionType {
  type: BrandActions.setIsNewUser;
  payload?: boolean;
}
export const setIsNewUserActionCreator = (
  isFirstTime: boolean
): SetIsNewUserActionType => {
  return { type: BrandActions.setIsNewUser, payload: isFirstTime };
};

export interface SetCurrentStoreActionType {
  type:
    | BrandActions.setCurrentStore
    | AuthActions.refreshTokenSuccess
    | AuthActions.refreshTokenFailed
    | AuthActions.refreshTokenLoading;
  payload: FiraStore;
}
export const setCurrentStoreActionCreator = (
  sessionId: string,
  store: FiraStore
) => {
  return async (
    dispatch: ThunkDispatch<
      SetCurrentStoreActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: AuthActions.refreshTokenLoading });
      const response = await refreshTokenService(sessionId, store.id);
      dispatch({
        type: AuthActions.refreshTokenSuccess,
        payload: response.data,
      });
      dispatch({ type: BrandActions.setCurrentStore, payload: store });
    } catch (error) {
      console.error(error);
      dispatch({
        type: AuthActions.refreshTokenFailed,
        payload: handleError(error),
      });
    }
  };
};

interface UpdateCurrentStoreActionType {
  type: BrandActions.updateCurrentStore;
  payload: FiraStore;
}
export const updateCurrentStoreActionCreator = (
  store: FiraStore
): UpdateCurrentStoreActionType => {
  return { type: BrandActions.updateCurrentStore, payload: store };
};

export interface PostCreateStoresActionType {
  type:
    | BrandActions.createStoreLoading
    | BrandActions.createStoreSuccess
    | BrandActions.createStoreFailed
    | AuthActions.refreshTokenSuccess
    | AuthActions.refreshTokenFailed
    | AuthActions.refreshTokenLoading;
  payload?: FiraStore | RequestError;
}
export const postCreateStoresActionCreator = (
  sessionId: string,
  storeform: CreateStoreForm,
  image?: File
) => {
  return async (
    dispatch: ThunkDispatch<PostCreateStoresActionType, unknown, AnyAction>
  ) => {
    try {
      dispatch({ type: BrandActions.createStoreLoading });

      if (image) {
        const response = await uploadImageService(image);
        storeform.logo = response.data.images[0];
      }
      const response = await createStoreService(storeform);

      dispatch({ type: AuthActions.refreshTokenLoading });
      const refreshTokenResponse = await refreshTokenService(
        sessionId,
        response.data.id
      );
      dispatch({
        type: AuthActions.refreshTokenSuccess,
        payload: refreshTokenResponse.data,
      });
      dispatch({
        type: BrandActions.createStoreSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: BrandActions.createStoreFailed,
        payload: handleError(error),
      });
    }
  };
};

interface EditStoreProfileActionType {
  type:
    | BrandActions.editStoreProfileLoading
    | BrandActions.editStoreProfileSuccess
    | BrandActions.editStoreProfileFailed;
  payload: RequestError | FiraStore;
}

export const editStoreProfileActionCreator = (
  id: string,
  editStoreForm: FiraStore,
  image?: File,
  favicon?: File
) => {
  return async (
    dispatch: ThunkDispatch<EditStoreProfileActionType, unknown, AnyAction>
  ) => {
    try {
      dispatch({ type: BrandActions.editStoreProfileLoading });
      if (image) {
        const response = await uploadImageService(image);
        editStoreForm.brand.logo = response.data.images[0];
      }
      if (favicon) {
        const response = await uploadImageService(favicon);
        editStoreForm.brand.favicon = response.data.images[0];
      }
      const response = await editStore(id, editStoreForm);
      dispatch({
        type: BrandActions.editStoreProfileSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: BrandActions.editStoreProfileFailed,
        payload: { data: handleError(error) },
      });
    }
  };
};

interface EditStoreNameActionType {
  type:
    | BrandActions.editStoreNameLoading
    | BrandActions.editStoreNameSuccess
    | BrandActions.editStoreNameFailed;
  payload: RequestError | string;
}
export const editStoreNameActionCreator = (id: string, name: string) => {
  return async (
    dispatch: ThunkDispatch<EditStoreNameActionType, unknown, AnyAction>
  ) => {
    try {
      dispatch({ type: BrandActions.editStoreNameLoading });

      await editStoreName(id, name);
      dispatch({
        type: BrandActions.editStoreNameSuccess,
        payload: name,
      });
    } catch (error) {
      dispatch({
        type: BrandActions.editStoreNameFailed,
        payload: { data: handleError(error) },
      });
    }
  };
};

interface ResetEditStoreMessageActioType {
  type: BrandActions.resetEditStoreMessage;
}
export const resetEditStoreMessageActionCreator =
  (): ResetEditStoreMessageActioType => {
    return { type: BrandActions.resetEditStoreMessage };
  };

interface ResetCreateStoreMessageActioType {
  type: BrandActions.resetCreateStoreMessage;
}
export const resetCreateStoreMessageActionCreator =
  (): ResetCreateStoreMessageActioType => {
    return { type: BrandActions.resetCreateStoreMessage };
  };

interface SetSyncStoreCodeActionType {
  type: BrandActions.setSyncStoreCode;
  payload: string | null;
}
export const setSyncStoreCodeActionCreator = (
  syncCode: string | null
): SetSyncStoreCodeActionType => {
  return {
    type: BrandActions.setSyncStoreCode,
    payload: syncCode,
  };
};

interface UpdateCurrentEcommerceActionType {
  type: BrandActions.updateCurrentEcommerce;
  payload: Ecommerce[];
}
export const updateCurrentEcommerceActionCreator = (
  ecommerces: Ecommerce[]
): UpdateCurrentEcommerceActionType => {
  return {
    type: BrandActions.updateCurrentEcommerce,
    payload: ecommerces,
  };
};

export interface ResetCurrentStoreActionType {
  type:
    | BrandActions.resetCurrentStore
    | AuthActions.refreshTokenSuccess
    | AuthActions.refreshTokenFailed
    | AuthActions.refreshTokenLoading;
}
export const resetCurrentStoreActionCreator = (sessionId: string) => {
  return async (
    dispatch: ThunkDispatch<
      ResetCurrentStoreActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: AuthActions.refreshTokenLoading });
      const response = await refreshTokenService(sessionId);
      dispatch({
        type: AuthActions.refreshTokenSuccess,
        payload: response.data,
      });
      dispatch({ type: BrandActions.resetCurrentStore });
    } catch (error) {
      dispatch({
        type: AuthActions.refreshTokenFailed,
        payload: handleError(error),
      });
    }
  };
};
interface UpdateOwnerStoreActionType {
  type:
    | BrandActions.updateOwnerStoreLoading
    | BrandActions.updateOwnerStoreSuccess
    | BrandActions.updateOwnerStoreFailed;
  payload: FiraStore | RequestError;
}

export const updateOwnerStoreActionCreator = (id: string, store: FiraStore) => {
  return async (
    dispatch: ThunkDispatch<
      UpdateOwnerStoreActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: BrandActions.updateOwnerStoreLoading });
      const response = await updateOwnerStore(id, store);
      dispatch({
        type: BrandActions.updateOwnerStoreSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: BrandActions.updateOwnerStoreFailed,
        payload: handleError(error),
      });
    }
  };
};

interface GetOwnerStoreActionType {
  type:
    | BrandActions.getOwnerStoresLoading
    | BrandActions.getOwnerStoresSuccess
    | BrandActions.getOwnerStoresFailed;
  payload: FiraStore[] | RequestError;
}

export const getOwnerStoresActionCreator = (businessClientId: string) => {
  return async (
    dispatch: ThunkDispatch<
      GetOwnerStoreActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: BrandActions.getOwnerStoresLoading });
      const response = await ownerStoreList(businessClientId);
      dispatch({
        type: BrandActions.getOwnerStoresSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: BrandActions.getOwnerStoresFailed,
        payload: handleError(error),
      });
    }
  };
};

interface CreateOwnerStoreActionType {
  type:
    | BrandActions.createOwnerStoreLoading
    | BrandActions.createSOwnertoreSuccess
    | BrandActions.createOwnerStoreFailed;
  payload: FiraStore | RequestError;
}

export const createOwnerStoreActionCreator = (body: CreateOwnerStoreForm) => {
  return async (
    dispatch: ThunkDispatch<
      CreateOwnerStoreActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: BrandActions.createOwnerStoreLoading });
      const response = await createOwnerStoreService(body);
      dispatch({
        type: BrandActions.createSOwnertoreSuccess,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: BrandActions.createOwnerStoreFailed,
        payload: handleError(error),
      });
    }
  };
};

interface CleanSuccessMessageActionType {
  type: BrandActions.cleanSuccessMessage;
}
export const cleanSuccessMessageActionCreator =
  (): CleanSuccessMessageActionType => {
    return { type: BrandActions.cleanSuccessMessage };
  };

interface RemoveCurrentStoreActionType {
  type:
    | BrandActions.removeCurrentStore
    | AuthActions.refreshTokenSuccess
    | AuthActions.refreshTokenFailed
    | AuthActions.refreshTokenLoading;
}

export const removeCurrentStoreActionCreator = (sessionId: string) => {
  return async (
    dispatch: ThunkDispatch<
      RemoveCurrentStoreActionType,
      Record<string, unknown>,
      AnyAction
    >
  ) => {
    try {
      dispatch({ type: AuthActions.refreshTokenLoading });
      const response = await refreshTokenService(sessionId);
      dispatch({
        type: AuthActions.refreshTokenSuccess,
        payload: response.data,
      });
      dispatch({ type: BrandActions.removeCurrentStore });
    } catch (error) {
      console.error(error);
    }
  };
};

export type BrandActionType =
  | GetUserStoresActionType
  | SetIsNewUserActionType
  | SetCurrentStoreActionType
  | LogoutActionType
  | PostCreateStoresActionType
  | EditStoreProfileActionType
  | EditStoreNameActionType
  | ResetEditStoreMessageActioType
  | ResetCreateStoreMessageActioType
  | RefreshTokenActionType
  | SetSyncStoreCodeActionType
  | UpdateCurrentEcommerceActionType
  | ResetCurrentStoreActionType
  | UpdateOwnerStoreActionType
  | GetOwnerStoreActionType
  | CreateOwnerStoreActionType
  | CleanSuccessMessageActionType
  | RemoveCurrentStoreActionType
  | UpdateCurrentStoreActionType;
