export const TRANSLATIONS_PT = {
  auth: {
    express: {
      fira: {
        success_description:
          "Este é o endereço web do meu site pessoal. Salve-o e compartilhe com seus amigos.",
        failed_description:
          "Oops! Parece que algo deu errado. Por favor, tente novamente.",
      },
      title: "Crie seu Link único",
      success_title: "Olá ❤️",
      success_description:
        "Este é o seu Link Único para que você e seus clientes possam acessar o Live Shopping Mary Kay. Copie e compartilhe!",
      failed_description: "Bem-vinda!!!",
      disabled: {
        title: "Estimado usuário",
        message:
          "O login está inativo no momento. Por favor, entre em contato com a equipe administrativa para obter mais informações.",
        closing: "Obrigado pela sua compreensão.",
      },
    },
    header: {
      welcome_back: "Bem-vindo de volta",
      sign_up: "Cadastre-se Agora!",
      recovery: "Redefinir senha",
      pwdcode: "Código de recuperação",
      changepwd: "Alteração de senha",
      activate: "Código de ativação",
      invitation_message:
        "A marca {referer} o convidou a se juntar à sua equipe",
    },
    tabs: {
      login: "Entrar",
      create_account: "Criar conta",
    },
    login: {
      message: "As senhas devem ter pelo menos 8 caracteres.",
      user: "Usuário / E-mail",
      password: "Senha",
      password_recovery: "Redefinir senha",
      button: "Entrar",
    },
    password_recovery: {
      message: "Enviaremos instruções para redefinir sua senha.",
      email: "E-mail",
      button: "Enviar",
    },
    pwdcode: {
      message:
        "O código de recuperação foi enviado para seu e-mail. Por favor, insira o código:",
      recovery_message: "Não recebeu o código de recuperação?",
      resend: "Reenviar",
      button: "Enviar",
    },
    changepwd: {
      new_pass: "Nova senha",
      confirm_pass: "Confirmar senha",
      button: "Entrar",
    },
    alerts: {
      registration_success: "Sua senha foi alterada com sucesso",
      activation_success: "Sua conta foi ativada com sucesso",
    },
    register: {
      name: "Nome",
      lastname: "Sobrenome",
      email: "E-mail",
      username: "Usuário",
      password: "Senha",
      confirm_pass: "Confirmar senha",
      terms_and_conditions: "Ao registrar sua conta, você aceita nossos",
      terms_and_conditions_link: "Termos de Serviço e Política de Privacidade.",
      button: "Criar Conta",
      message: "* Use pelo menos 8 caracteres",
    },
    activation: {
      message:
        "O código de ativação foi enviado para seu e-mail. Por favor, insira o código:",
      resend_message: "Não recebeu o código de ativação?",
      resend: "Reenviar",
      button: "Enviar",
    },
    messages: {
      error: {
        username_not_found: "Você não possui uma conta na FIRA",
        create_account: "Criar conta",
        email_not_found: "Você não possui uma conta na FIRA",
        not_activated: "Você não possui uma conta na FIRA",
        invalid_password: "Credenciais incorretas",
        wrong_credentials: "Esqueceu sua senha?",
        unavailable_user: "Usuário ou e-mail já registrados",
        recover_password: "Deseja recuperar sua senha?",
        invalid_user_code: "Código de ativação incorreto",
        try_again: "Tente novamente",
        expired_user_code: "Código de ativação inválido",
        request_new_code: "Solicitar um novo código",
        default: "Encontramos um erro, tente novamente",
      },
    },
  },
  views: {
    products: {
      title: "Produtos",
      exportButton: "Exportar",
      importButton: "Importar",
      addProductButton: "Adicionar Produto",
      tableHeader: {
        name: "Nome",
        inventory: "Estoque",
        price: "Preço",
        priceWithTaxes: "Preço",
        extraPrice: "Preço",
        discount: "Desconto",
        visibility: "Visibilidade",
      },
      detailProduct: {
        newProduct: "Novo Produto",
        save: "Salvar",
        edit: "Editar",
        saving: "Salvando...",
        saved: "Produto salvo",
        imageError: "Erro no carregamento da imagem",
        error: "Encontramos um erro, tente novamente",
        discard: "Descartar",
        form: {
          name: "Nome",
          SKU: "SKU",
          skuInfo: "Código único de inventário atribuído a um produto",
          exterId: "Id Externo",
          exterIdInfo: "Código único de um produto gerado pelo e-commerce",
          inventory: "Estoque",
          URL: "URL",
          price1: "Preço",
          price2: "Preço 2",
          price3: "Preço 3",
          media: "Elementos multimídia",
          discount: "Preço com desconto",
          optional: "Desconto (opcional)",
          images:
            "A imagem principal é a que aparecerá primeiro na capa do produto durante a transmissão",
          retrieve: "Recuperar",
          addImage: "Tamanho máx 1080px x 1080px",
          description: "Descrição",
        },
        origin: "Origem",
        categoryTitle: "Categorias",
        variationOption: "Opções de Variações",
        addButton: "Adicionar",
        delete: "Excluir",
        placeholderSearch: "Toque para pesquisar...",
        variant: "Variante",
        inventory: "Estoque",
        price1: "Preço1",
        price2: "Preço2",
        labels: "Etiquetas",
        label: "Etiqueta",
        example: "Ex: Oferta",
        addImageVar: "Atribuir imagem a uma variante",
        cancel: "Cancelar",
        ready: "Pronto",
        variationName: "Nome da variação",
        variationValue: "Valores de variação",
        addValue: "Adicionar valor",
        fileFormat: "Formato de arquivo",
        sizeRecommended: "Tamanho recomendado",
        maximumWeight: "Peso máximo do arquivo",
        addVariant: "Adicionar Variante",
        emptyVariant: "Todas as variações foram adicionadas",
        errorImages:
          "Somente imagens nos formatos .jpg .jpeg .png são permitidas",
        stores: "Lojas",
        search: "Toque para pesquisar...",
      },
      visibiliy: {
        owner: "Dono",
        total: "Total",
        partial: "Parcial",
      },
    },
    events: {
      title: "Eventos",
      status: {
        broadcast: {
          scheduled: "Agendado",
          started: "Iniciado",
          not_started: "Não iniciado",
          cancelled: "Cancelado",
          done: "Finalizado",
          config: "Configurar",
        },
        test: {
          scheduled: "Teste agendado",
          started: "Teste iniciado",
          not_started: "Teste não iniciado",
          cancelled: "Teste cancelado",
          done: "Finalizado",
          config: "Configurar",
        },
      },
      header: {
        listButton: "Lista de Eventos",
        today: "Hoje",
        day: "Dia",
        month: "Mês",
        week: "Semana",
        eventName: "Nome do Evento",
        date: "Data",
        startHour: "Hora de início",
        endHour: "Hora de término",
        products: "Produtos",
        status: "Status",
        options: "Opções",
        filter: "Toque para pesquisar....",
      },
      table: {
        products: "produtos",
        statusOptions: {
          scheduled: "Agendado",
          initEvent: "Iniciar Evento",
          live: "Ao vivo",
          finished: "Finalizado",
          cancelled: "Cancelado",
          config: "Configurar",
        },
      },
      new_popup: {
        event_name: "Nome do evento",
        placeholderSearch: "Pesquisar loja...",
        start_time: "Data",
        time: "Hora",
        button_create: "Criar Live",
        button_create_test: "Criar live de teste",
        placeholder: "DD/MM/AA",
        simulcastEvent: "Evento simultâneo",
        independentEvents: "Eventos independentes",
        infoSimulcast:
          "Transmitir um evento simultaneamente em várias lojas, que será realizado pela conta principal",
        infoIndependent:
          "Agendar um evento independente simultaneamente para várias lojas",
      },

      popup: {
        event_name: "Nome do Evento",
        start_time: "Hora de início",
        end_time: "Hora de término",
        button_create: "Criar Evento",
      },
      popup_top: {
        event_name: "Nome do Live",
        start_time: "Data de início",
        time: "Hora",
        button_create: "Criar Live",
        button_create_test: "Criar live de teste",
        placeholder: "DD/MM/AA",
      },

      popup_option: {
        moderation: "Moderar",
        statistics: "Detalhes da Transmissão",
        edit_event: "Editar",
        cancel_event: "Cancelar",
        event_canceled: "Evento cancelado",
      },
      eventDetail: {
        moderationTitle: "{{eventName}} | Moderação",
        statisticsTitle: "{{eventName}} | Estatísticas",
        credentials: "Credenciais",
        statistics: "Resumo LIVE",
        postStats: "Resumo PÓS-LIVE",
        moderation: "Moderação",
        likes: "Curtidas",
        shares: "Compartilhamentos",
        connections: "Audiência",
        averageConnectionTime: "Média de visualização",
        connectionTime: "Tempo de visualização",
        bouncingRate: "Taxa de rejeição",
        engagement: "Engajamento",
        averageClicks: "Cliques",
        chatMessages: "Mensagens do chat",
        liveTime: "Tempo do live",
        start: "Iniciar Live",
        finish: "Finalizar Live",
        startTest: "Iniciar teste",
        finishTest: "Finalizar teste",
        exportData: "Exportar",
        processing: "Processando...",
        infoId: "Identificador único de um evento",
        messages: {
          copy_success: "copiado com sucesso!",
          invalid_id: "ID de Stream {streamId} inválido",
          not_started:
            "Não é possível finalizar uma transmissão com um status diferente de iniciado",
          no_value: "Erro 500, Nenhum valor presente",
          other_started: "Existe outro evento iniciado",
          red5_error:
            "No momento não é possível gerar as credenciais, tente novamente mais tarde",
          cant_generate_stats:
            "Ocorreu um erro ao baixar as estatísticas, por favor verifique a configuração da Loja",
        },
        instructions: {
          title: "Inicie o live",
          instruction: "Caso as credenciais não existam, clique em credenciais",
        },
        fakeChat: {
          title: "Ainda não há chats",
          subTitle: "Envie uma mensagem para iniciar a conversa.",
          refresh: "Atualize o chat para poder interagir.",
          refreshButton: "Atualizar",
        },
        products: {
          title: "Pop-ups",
          messages: {
            popupError: "Você só pode ter 2 produtos em destaque",
          },
          filter: "Toque para pesquisar....",
          add: "Adicionar",
          highlight: "Destacar",
        },
        chat: {
          input: "Texto..",
        },
        skeleton: {
          message:
            "Inicie a transmissão usando seu software de streaming favorito e clique em “atualizar” para começar a ver a prévia do LIVE",
          refresh: "Atualizar",
          finished: "Evento Finalizado",
        },
        users_table: {
          title: "Dados dos espectadores",
          subtitle: "Dados capturados durante o live",
          export: "Exportar",
          filter: "Toque para pesquisar....",
          username: "Nome",
          email: "E-mail",
          phone: "Telefone",
          bpaCount: "Cliques em Produtos",
        },
        products_clicks: {
          title: "Cliques em produtos",
          subtitle: "Número exato de cliques por produto",
          product: "Produto",
          clicks: "Cliques",
          interactionTitle: "Cliques em produto - interação",
          interactionSubtitle: "Número exato de cliques por produto",
          gallery: "Galeria",
          highlighted: "Destaques",
          interactionTotal: "Total de interação",
          buyTitle: "Cliques em produto - intenção de compra",
          buySubtitle: "Número exato de cliques em “comprar” por produto",
          buyIntent: "Intenção de compra",
        },
        player: {
          connecting: "Conectando",
          reconnecting: "Reconectando",
          finished: "Evento Finalizado",
          refresh: "Atualizar",
          text_1: "Você está tendo dificuldades de conexão,",
          text_2: " pressione o botão atualizar ",
          text_3: "para reconectar.",
        },
        statiticsChart: {
          title: "Dados gerais",
          subtitle:
            "Espaço para realizar um comparativo entre métricas específicas",
          likes: "Curtidas",
          shares: "Compartilhamentos",
          connections: "Audiência",
          messages: "Mensagens do chat",
          clicks: "Cliques",
        },
        concurrencyChart: {
          title: "Concorrência",
          subtitle:
            "Quantidade exata de usuários presentes por minuto durante o live",
          connections: "Conexões",
        },
        optionsDropdown: {
          likes: "Curtidas",
          shares: "Compartilhamentos",
          clicks: "Cliques",
          messages: "Mensagens",
        },
      },
      calendar: {
        showMore: " mais",
        moderate: "Moderar",
        detail: "Detalhes da transmissão",
        canceled: "Cancelado",
        config: "Configurar",
        modList: {
          title: "Participantes",
          roles: {
            admin: "Administrador",
            seller: "Vendedor",
            proseller: "Proseller",
            operator: "Operador",
            manager: "Administrador",
            moderator: "Moderador",
            account_owner: "Proprietário da conta",
          },
        },
      },
    },
    users: {
      title: "Equipe",
      active: "Ativos",
      inactive: "Inativos",
      add: "Adicionar membro",
      table: {
        name: "Nome",
        email: "E-mail",
        phone: "Telefone",
        permissions: "Permissões",
        total: "Total",
        partial: "Parcial",
        none: "Nenhum",
        inactive: "Inativo",
        admin: "Administrador",
      },
      messages: {
        update:
          "As permissões de {{firstName}} {{lastName}} foram modificadas com sucesso",
        invitation: "Convite enviado com sucesso",
        error_user_currently: "Usuário está atualmente nesta Loja",
      },
    },
    edit_permissions: {
      title: "Equipe - {nameToShow}",
      dismiss_button: "Descartar",
      save_button: "Salvar",
      role: "Função:",
      select_operator: "Selecione a função do operador",
      profile: "Perfil",
      select_operator_profile: "Selecione o perfil do operador",
      roles: {
        admin: "Administrador",
        seller: "Vendedor",
        proseller: "Proseller",
        operator: "Operador",
        manager: "Administrador",
        moderator: "Moderador",
        account_owner: "Proprietário da conta",
      },
    },
    dashboard: {
      title: "Dashboard",
      infoText: "Visualize o resumo de suas estatísticas",
      transmissionsNumber: "Quantidade de lives",
      testTransmissionsNumber: "Lives de teste",
      transmissionsNumberDesc:
        "Quantidade de eventos realizados no período selecionado",
      testTransmissionsNumberDesc:
        "Quantidade de eventos de teste realizados no período selecionado",
      transmissionsTime: "Tempo de lives",
      transmissionsTimeDesc: "Tempo utilizado durante suas sessões LIVE",
      engagement: "Engajamento",
      engagementDesc:
        "Porcentagem de interação dos usuários ativos durante os LIVES.",
      connections: "Visualizações",
      connectionsDesc:
        "Número de pessoas que visualizaram o live por dispositivo.",
      rate: "Taxa de rejeição",
      rateDesc: "Porcentagem de usuários que não interagiram durante o LIVE.",
      time: "Média de visualização",
      timeDesc: "Tempo médio de permanência dos clientes em suas sessões LIVE",
      timeVisual: "Tempo de visualização",
      testingLives: "Lives de testes",
      audienceCount: "Quantidade de audiência",
      totalAudience: "Audiência total",
      reproductionTotal: "Total de reprodução",
      reproductionAverage: "Média de reprodução",
      transmissionTime: "Tempo de transmissão",

      events: "Eventos",
      Metrics: "Métricas",
      titleTable: "Eventos recentes",
      subTitleTable: "Visualize aqui os lives finalizados",
      nextsEvents: "Eventos",

      from: "De",
      to: "Até",
      noEvents: "Sem Eventos",
      showEvents: "Ver eventos",
      table: {
        name: "Nome",
        date: "Data",
        products: "Produtos",
        likes: "Curtidas",
        shares: "Compartilhamentos",
        audience: "Audiência",
      },
      MetricsSelect: {
        audience: "Audiência",
        conversion: "Conversão",
        likes: "Curtidas",
        shares: "Compartilhamentos",
      },
      dateRangeSelect: {
        personalized: "personalizado",
        today: "hoje",
        yesterday: "ontem",
        lastSeven: "últimos 7 dias",
        lastThirty: "últimos 30 dias",
        lastNinety: "últimos 90 dias",
        lastMonth: "mês passado",
        lastYear: "ano passado",
        thisWeek: "esta semana até a data",
        thisMonth: "este mês até a data",
        thisTrimester: "este trimestre até a data",
      },
      greetings: {
        goodMorning: "Bom dia",
        goodAfternoon: "Boa tarde",
        goodEvening: "Boa noite",
        welcome: "Bem-vindo",
        toAccount: "à conta",
      },

      reproductionAccum: "Tempo total de reprodução",
    },
    dashboardOwner: {
      title: "Dashboard",
      infoText: "Seu resumo de desempenho deste mês",
      widgetStatsStoreTitle: "Porcentagem de estatísticas",
      widgetStatsStoreSubTitle:
        "Compare e contraste os cliques gerados por cada loja",
      tableWidgetTitle: "Lojas administradas",
      tableWidgteSubTitle:
        "Neste espaço você poderá visualizar todas as lojas da conta",
      livesCount: "Quantidade de lives",
      transmisionCount: "Tempo de transmissão",
      audienceTotal: "Audiência total",
      livesTest: "Lives de testes",
    },
    dashboardOwnerVideos: {
      audienceTotal: "Audiência total",
      store: "Loja",
      audience: "Audiência",
      viewsAverage: "Reproduções médias",
      viewsTotal: "Reproduções totais",
      tableDescription:
        "Neste espaço você poderá visualizar todas as lojas da conta com sua audiência e quantidade de reproduções, média e total",
    },

    firaTv: {
      title: "Widgets",
      settings: "Configurações",
      sectionTitle: "Título da seção",
      nextEvents: "Próximos eventos",
      pastEvents: "Eventos anteriores",
      selectWidget: "Selecione um widget da lista",
      selectEventType: "Selecione os vídeos a serem exibidos",
      selectEventColors: "Selecione a cor",
      copyCode: "Copie o código abaixo em sua página web",
      copy: "Copiar",
      desktop: "Visão desktop",
      mobile: "Visão mobile",
      widgetSelectionView: {
        title: "Configurações @widget",
        searchbar: "Toque para pesquisar...",
        cards: "Cartões",
        mosaic: "Mosaico",
        banners: "Banners",
        collections: "Coleções",
        carousel: "Carrossel",
        calendar: "Calendário",
        scheduled: "Agendado",
        thumbnails: {
          collectionCarousel: "Coleção carrossel",
          comingSoon: "Em breve",
          collectionCard: "Coleção cartão",
          vodGallery: "Galeria de VOD",
          bannerCommercial: "Banner comercial",
          bannerSlider: "Banner slider",
          bannerLive: "Banner superior - ao vivo ou VOD",
          categories: "Categorias",
        },
      },
      widgets: {
        cards: {
          COLLECTION_CARDS: {
            primaryTextColor: "Próximos eventos, nome do evento, descrição",
            secondaryTextColor: "Data e hora, “ver mais”",
            buttonTextColor: "Texto do botão",
            primaryColor: "Detalhes e botão",
            cardColor: "Cartões de evento",
            backgroundColor: "Fundo",
            infoCardDescription:
              "Utilize este widget para apresentar à sua audiência uma coleção de vídeos, com informações sobre o momento em que foram realizados e uma breve descrição de cada um.",
            title: "Coleção cartão",
          },
        },
        scheduled: {
          COMING_SOON: {
            title: "Em breve",
            infoCardDescription:
              "Utilize este widget para informar sua audiência sobre um dos eventos agendados, incluindo o tempo restante até o início, e oferecer a opção de receber notificações no momento do início.",
            primaryTextColor: "Nome do evento, descrição, temporizador",
            displayColor: "Display",
            buttonTextColor: "Texto do botão",
            primaryColor: "Detalhes e botão",
            backgroundColor: "Fundo",
          },
        },
        carousel: {
          COLLECTION_CAROUSEL: {
            title: "Coleção carrossel",
            infoCardDescription:
              "Utilize este widget para destacar os eventos mais relevantes de suas coleções para sua audiência. Com este widget você poderá mostrar a categoria, o nome do evento e a data em que ocorreu. Serão exibidos dois eventos por vez.",
            primaryTextColor: "Eventos em destaque",
            displayColor: "Display",
            primaryColor: "Detalhes",
            backgroundColor: "Fundo",
          },
        },
        grid: {
          VOD_GALLERY: {
            title: "Galeria de VOD",
            infoCardDescription:
              "Utilize este widget para mostrar à sua audiência os vídeos armazenados na biblioteca de coleções. Além disso, este widget pode servir como página de redirecionamento em combinação com outros widgets que também redirecionam a coleções. Com este widget, é possível alterar a organização e os filtros dos eventos.",
            primaryTextColor: '"FILTROS"',
            secondaryTextColor: 'Checkbox, "ver mais"',
            tertiaryTextColor: "Nome do filtro",
            primaryColor: "Texto das categorias",
            secondaryColor: "# de vídeos na categoria",
            tertiaryColor: "Fundo do botão de ordenar",
            quaternaryColor: "Ordem selecionada",
            quinaryColor: "Ordem não selecionada",
            backgroundColor: "Fundo",
          },
        },
        banners: {
          SLIDER: {
            title: "Banner slider",
            infoCardDescription:
              "Utilize este widget de banner deslizante para exibir na página principal os eventos mais atuais.",
            primaryTextColor: "Nome da coleção",
            secondaryTextColor: "Nome da categoria",
            tertiaryTextColor: "Cor do texto do botão",
            primaryColor: "Cor do botão",
            secondaryColor: "Cor do slide ativo e cor das setas",
            tertiaryColor: "Cor de fundo das setas",
            quaternaryColor: "Cor do slide inativo",
          },
          COMMERCIAL: {
            title: "Banner comercial",
            infoCardDescription:
              "Utilize este widget para apresentar à sua audiência um evento em destaque ou direcioná-los para uma coleção específica.",
            inputTitle: "VÍDEO ASSOCIADO AO BANNER",
          },
          TOP: {
            title: "banner superior - ao vivo ou VOD",
            infoCardDescription:
              "Utilize este widget para manter seus usuários atualizados sobre os vídeos ao vivo ou um vídeo em particular que você selecionou.",
          },
        },
        collections: {
          CATEGORIES: {
            title: "Categorias",
            infoCardDescription:
              "Utilize este widget para mostrar à sua audiência as diversas categorias de eventos que foram transmitidos. Na página principal, serão exibidos com uma imagem representativa e o nome da categoria.",
          },
        },
      },
      exampleItems: {
        eventName: "Nome do evento",
        eventDescription: "Descrição do evento",
        fakeDate: "segunda-feira, dezembro de 2022",
        titlePlaceholder: "Título da seção",
      },
    },
    videos: {
      route: "Vídeos",
      title: "Galeria de vídeos",
      table: {
        name: "Vídeo",
        type: "Tipo",
        visibility: "Visibilidade",
        date: "Data de transmissão",
        category: "Categoria",
        options: {
          hide: "Oculto",
          visible: "Visível",
        },
        state: {
          processing: "Processando...",
          processed: "Processado",
          failed: "Falhou",
        },
      },
      filter: "Toque para pesquisar...",
      filterOptions: {
        recent: "Mais recente",
        older: "Mais antigo",
        viewed: "Mais visualizados",
        alphabet: "de A a Z",
        reverse: "de Z a A",
        like: "Mais curtidas",
        share: "Mais compartilhados",
      },
      steps: {
        generalData: "Dados Gerais",
        video: "Vídeo",
        products: "Produtos",
        comments: "Comentários",
        next: "Próximo",
        end: "Finalizar",
      },
      editVideo: {
        general_details: {
          title: "Informações gerais",
          message:
            "Preencha as informações a seguir relacionadas ao seu evento",
          forms: {
            event_name: "Nome do vídeo",
            datetime: "Data e hora",
            desc: "Descrição do vídeo",
            select_categories: "Selecionar categorias",
            load_teaser: "Carregar teaser",
            load_image: "Carregar imagem",
            optional: "(Opcional)",
            teaser_info: "Vídeo com prévia do evento",
            file_format: "Formato de arquivo",
            recommended_size: "Tamanho recomendado",
            image_info: "Imagem de capa para seu evento",
            placeholder: "Toque para pesquisar...",
          },
        },
      },
      errors: {
        category: "Não é possível excluir todas as categorias",
        stats: "Ocorreu um erro ao baixar as estatísticas.",
      },
      labels: {
        titleStats: "Dados gerais",
        subtitleStats:
          "Espaço para realizar uma comparação entre métricas específicas",
        current: "Hoje",
        yesterday: "Ontem",
        lastWeek: "Última semana",
        lastTwoWeeks: "Últimas duas semanas",
        lastMonth: "Último mês",
        lastTrimester: "Último trimestre",
        lastSemester: "Último semestre",
        lastYear: "Último ano",
        yourStores: "Suas lojas",
        titlePieChart: "Porcentagem de estatísticas",
        subtitlePieChart: "Compare os dados a seguir entre cada uma das lojas.",
      },
      selects: {
        filter: "Filtros",
      },
      productList: {
        title: "Produtos em destaque",
        subtitle: "Produtos com maior interação por loja",
      },
      previewLink: "Pré-visualização",
    },
    collections: {
      title: "Coleções",
      titlePage: "Coleções de vídeos",
      createCollection: "Criar coleção",
      table: {
        name: "Nome da coleção",
        date: "Data de criação",
        videos: "Vídeos",
        id: "ID da coleção",
      },
      details: {
        newTitle: "Nova coleção",
        discard: "Descartar",
        save: "Salvar",
        collectionName: "Nome da coleção",
        description: "Descrição",
        cover: "Imagem de capa para sua coleção",
        listTitle: "Vídeos da coleção",
        novideos:
          "Não há vídeos nesta coleção,\n pesquise ou explore para adicionar vídeos.",
        table: {
          name: "Nome",
          desc: "Descrição",
          teaser: "Teaser",
          image: "Imagem",
          products: "Produtos",
          addButton: "Adicionar",
        },
        delete: "Excluir coleção",
        deleteAlert: "Deseja excluir {{collectionName}}?",
        deleteMessage: "Esta ação não pode ser desfeita.",
        cancel: "Cancelar",
        saved: "Coleção salva com sucesso",
        discardAlert: "Deseja salvar antes de sair da página?",
        discardMessage:
          "Ao sair desta página, todas as alterações não salvas serão perdidas.",
        leavePageButton: "Sair da página",
        saveAndLeave: "Salvar e sair",
        error: "Ocorreu um erro. Por favor, tente novamente",
      },
    },
    stores: {
      title: "Lojas",
      createBtn: "Criar loja",
      importBtn: "Importar",
      exportBtn: "Exportar",
      table: {
        storeName: "Nome",
        status: "Status",
        storeId: "ID da loja",
        detailsBtn: "Ver detalhes",
        accessBtn: "Acessar",
        role: "Função",
        roleTypeAdmin: "Administrador",
        roleTypeSeller: "Vendedor",
      },
      status: {
        onhold: "Em espera",
        active: "Ativo",
        suspended: "Suspenso",
        cancelled: "Cancelado",
        done: "Concluído",
        deactivated: "Inativo",
        deleted: "Excluído",
        approved: "Aprovado",
        pending: "Pendente",
        rejected: "Rejeitado",
        inprocess: "Em processo",
        valid: "Válido",
        invalid: "Inválido",
      },
      createSuccess: "Você criou a loja com sucesso!",
      details: {
        accessBtn: "Acessar loja",
        createdDate: "Criada em",
        contact: "Contato",
        country: "País",
        currency: "Moeda",
        city: "Cidade",
        website: "Site",
        editBtn: "Editar informações",
        transferBtn: "Transferir propriedade",
        deleteBtn: "Excluir loja",
        table: {
          name: "Nome do Operador",
          status: "Status",
          role: "Função",
          operatorId: "ID do operador",
          seeDetails: "Ver detalhes",
          roles: {
            admin: "Administrador",
            seller: "Vendedor",
            proseller: "ProSeller",
            operator: "Operador",
            manager: "Gerente",
            moderator: "Moderador",
            account_owner: "Proprietário da conta",
          },
        },
      },
      error: "Ocorreu um erro. Por favor, tente novamente",
      storeDeleteSuccess: "A loja foi excluída com sucesso!",
    },
    team: {
      title: "Equipe",
      invites: "Convites",
      name: "Nome",
      status: "Status",
      seeProfileBtn: "Ver perfil",
      teamMember: "Criar operador",
      inviteMember: "Convidar operador",
      details: {
        title: "Lojas",
        table: {
          storeName: "Nome da Loja",
          role: "Função",
          lastAccess: "Último acesso",
          permissions: "Ver permissões",
          deleteUserButton: "Excluir operador",
        },
      },
    },
    ownerCalendar: {
      title: "Calendário / Eventos",
    },
    operators: {
      title: "Adicionar um novo operador",
      invitation_title: "Informações do operador",
      invitation_desc:
        "Preencha os dados gerais do seu novo operador para continuar com a operação.",
      nextBtn: "Próximo",
      prevBtn: "Voltar",
      createBtn: "Criar",
      inviteBtn: "Convidar",
      name: "Nome do operador",
      email: "E-mail",
      operator_role: "Função do operador",
      operator_role_desc:
        "Escolha a função que o operador que você está criando irá ocupar.",
      operator_name: "Operador",
      operator_desc:
        "Este perfil terá limitações de funções dentro do serviço, de acordo com o nível que for atribuído, deverá ter um supervisor que poderá ver ou modificar suas ações.",
      operator_profile: "Perfil: Operador",
      operator_profile_desc:
        "Selecione os módulos aos quais você deseja que este usuário acesse",
      admin_name: "Administrador",
      admin_desc:
        "Este perfil será responsável por administrar a loja sob sua responsabilidade, sendo responsável por gerenciar os lives que realizar.",
      admin_profile: "Perfil: Administrador",
      admin_profile_desc:
        "Escolha o serviço no qual o Administrador será atribuído",
      moderator_name: "Moderador",
      moderator_desc:
        "Este perfil será responsável por interagir no chat dos lives aos quais for atribuído, terá gestão de mensagens, pop-ups, entre outras funções determinadas para um live.",
      moderator_profile: "Perfil: Moderador",
      moderator_profile_desc:
        "Escolha o serviço no qual o Moderador será atribuído",
      error: "Os dados não foram carregados, tente novamente!",
      duplicated_invitation: "O usuário possui um convite pendente",
      himself_invitation: "Você não pode se convidar",
      already_invited: "O usuário já pertence à loja",
      success: "Convite enviado para o e-mail: ",
    },
    landing: {
      title: "Fira Onlive",
      tag: "Novo",
      subLinks: {
        config: "Configurações",
        landing: "Landing page",
        preview: "Pré-visualização",
      },
      backBtn: "Voltar",
      saveBtn: "Salvar",
      createBtn: "Criar",
      editBtn: "Editar URL",
      infoLineOne: "Edite e personalize o final da sua URL.",
      infoLineTwo: "Ex: firaonlive.com/exemplourl",
      storeName: "NomeDaLandingPage",
      page: {
        title: "Página",
        headerHeight: "Altura do cabeçalho em px",
        colorStyle: "Estilo da cor",
        background: "Fundo",
        header: "Cabeçalho",
        footer: "Rodapé",
        logoAlignment: "Alinhamento do logo",
        left: "Esquerda",
        center: "Centro",
        shoppingCart: "Carrinho de compras",
        shoppingCartSwitch: "Adicionar carrinho de compras",
      },
      widgets: {
        title: "Widgets",
        widgetStyle: "Estilo do widget",
        titleLabel: "Título",
        screen: "Tela",
        details: "Detalhes",
        background: "Fundo",
        widgetsDesc: "Selecione os eventos a serem exibidos",
        collection: "Coleção associada ao widget",
        collectionPlaceholder: "Nome da coleção",
        collectionError: "Você deve selecionar uma coleção para seu widget",
        selectWidget: "Selecionar widget",
        featured: "Lives em destaque",
        featuredInfo:
          "Ao ativar este widget, serão exibidos uma série de VODs.",
        featuredProducts: "Produtos em destaque",
        featuredProductsInfo:
          "Para usar este widget, o carrinho de compras deve estar ativado.",
        products: "Produtos",
        widgetTitle: "Título do widget",
        searchInput: "Toque para pesquisar...",
        widgetProductsErrorV2:
          "Você deve selecionar pelo menos um produto para o widget de produtos em destaque.",
      },
      typography: {
        title: "Tipografia",
        body: "Conteúdo",
        footer: "Rodapé",
      },
      catalog: {
        title: "Catálogo",
        moduleTitle: "Catálogo online",
        toggleLabel: "Ativar catálogo de produtos",
        products: "Produtos",
        desc: "Selecione os produtos que serão exibidos na seção de catálogo.",
        all: "Selecionar todos",
      },
      createSuccess: "Você criou a landing page com sucesso!",
      createError: "Ops! Parece que a URL já existe",
      updateSuccess: "Você editou a landing page com sucesso!",
      updateError: "Ocorreu um erro, por favor tente novamente",
      phoneError: "Por favor, insira um número de telefone válido",
      popUp:
        "Você não configurou sua landing page. Clique no botão de configuração para finalizar o processo.",
    },
  },
  components: {
    chat: {
      headerChatText: "Chat",
      headerUserText: "Usuários",
      inputPlaceholder: "Texto",
      deleteMenuButton: "Excluir",
      banMenuButton: "Bloquear usuário",
      unbanMenuButton: "Desbloquear",
      shadowBanMenuButton: "Silenciar usuário",
      removeShadowBaneMenuButton: "Retirar Silencio",
      pinMessageMenuButton: "Fixar mensagem",
      unpinMessageMenuButton: "Desfixar mensagem",
      noSearch: "Insira o nome do usuário",
      noUsers: "Nenhum usuário encontrado",
      loading: "Carregando...",
      searchPlaceholder: "Toque para pesquisar...",
      message: "Escreva uma mensagem...",
      sendButton: "Enviar",
      noStarted: "O evento não começou",
      info: "Informações registradas",
      detailsButton: "Detalhes do usuário",
      hide: "Ocultar",
      copy: "Copiado!",
      noData: "Sem dados",
    },
    table: {
      counterText: "Selecionados",
      optionsButton: "Excluir",
      searchPlaceHolder: "Toque para pesquisar....",
      filteringTitle: "Ordenar:",
    },
    navBar: {
      contextMenu: {
        changeBrandButton: "Mudar de marca",
        storeProfileButton: "Perfil da Loja",
        userProfileButton: "Perfil Pessoal",
        invitationsButton: "Meus Convites",
      },
      logoutButton: "Sair",
      settingButton: "Configurações",
      accountConfig: {
        title: "Configurações da conta",
        syncTitle: "Sincronização",
      },
      storeSettingButton: "Geral",
      storeInfoButton: "Informações da loja",
      profileSettingsButton: "Perfil Pessoal",
      userSecurityButton: "Segurança",
      profileRolesAndPermissions: "Funções e permissões",
    },
    synchronization: {
      title: "Sincronização com Ecommerce",
      unsunc: "Desincronizar",
      subtitle: "Adicionar e-commerce",
      description:
        "Escolha a plataforma de e-commerce com a qual você deseja fazer a sincronização",
      loading: "Processando...",
      success: "A sincronização foi bem-sucedida!",
      failed: "Falha na sincronização!. ",
      retry: "Tente novamente",
    },
    videoSettings: {
      title: "Configurações de vídeo",
      subtitle: "Substitua seu vídeo caso necessite de algum ajuste",
      replaceVideo: "Substituir vídeo",
      editVideo: "Editar vídeo",
      errorVideo: "Pré-visualização indisponível",
      popUpDecision: {
        title: "Tem certeza de que deseja substituir o vídeo?",
        message: "Lembre-se que esta ação só poderá ser realizada uma vez",
      },
      popUpError: {
        title: "Não foi possível concluir o carregamento!",
        message: "É necessário enviar o arquivo novamente.",
        retry: "Tentar novamente",
      },
      popUpFormatError: {
        title: "O formato do arquivo não é compatível",
        message: "Lembre-se que você deve enviar o arquivo no formato ",
      },
      popUpLoading: {
        title: "Enviando vídeo",
        loading: "Carregando...",
      },
      popUpSizeError: {
        title: "Tamanho do arquivo muito grande",
        description: "Lembre-se que o vídeo não pode pesar mais que ",
      },
      popUpOnlyOneMp4Error: {
        title: "Ocorreu um erro ao substituir o vídeo.",
        description: "Você só pode selecionar um arquivo .mp4.",
      },
      popUpTS: {
        title: "Ocorreu um erro ao substituir o vídeo.",
        description:
          "Por favor, selecione pelo menos um arquivo .m3u8 e pelo menos um arquivo .ts, ou apenas um arquivo .mp4.",
      },
    },
    imageUpload: {
      load: "Carregar imagem",
      fileFormat: "Formato de arquivo",
      fileSize: "Tamanho recomendado",
    },
    editProductsTable: {
      table: {
        name: "Nome",
        sku: "SKU",
        quantity: "Estoque",
        price: "Preço",
        discount: "Desconto",
        entry: "Entrada",
      },
      popup: {
        header: {
          hideProducts: "Ocultar todos os produtos",
          showProducts: "Mostrar todos os produtos",
          hidePrices: "Ocultar todos os preços",
          showPrices: "Mostrar todos os preços",
        },
        rows: {
          hideProduct: "Ocultar produto",
          showProduct: "Mostrar produto",
          hidePrices: "Ocultar preços",
          showPrices: "Mostrar preços",
        },
      },
      errors: {
        emptyInput: "O campo não pode ficar vazio",
      },
    },
    infoCard: {
      title: "Descrição do funcionamento",
      NEXT_EVENTS_CARROUSEL:
        "Use este widget para apresentar à sua audiência uma coleção de vídeos, com informações sobre quando eles foram feitos e uma breve descrição de cada um.",
    },
    widgetConfigCard: {
      title: "Geral",
      sectionTitle: "Estilo do widget",
    },
    selectEventsWidget: {
      title: "Selecionar eventos a serem exibidos",
      eventTypes: "Tipos de eventos",
      nextEvents: "Próximos eventos",
      prevEvents: "Eventos anteriores",
      collections: "Coleções",
    },
    selectByNameWidget: {
      eventTitle: "Evento associado ao widget",
      collectionTitle: "Coleção associada ao widget",
      select: "Selecionar",
      optional: "(opcional)",
    },
    selectByCollectionNameWidget: {
      collectionTitle: "Coleção associada ao widget",
      defaultv1: "Nome da coleção",
      multiple: "Coleções associadas ao widget MÁX 8",
    },
    selectByEventNameWidget: {
      title: "Vídeo associado ao banner",
      defaultv1: "Nome do evento",
    },
    widgetRadioButton: {
      videos: "Vídeos salvos",
      collections: "Coleções",
      videoTitle: "Vídeo associado ao banner",
    },
    widgetImageInput: {
      load: "Carregar imagem",
    },
    widgetUrlInput: {
      title: "Página de redirecionamento",
    },
    statusSelect: {
      active: "Ativo",
      inactive: "Inativo",
    },
    permissionCard: {
      title: "Permissões:",
      edit: "Editar Permissões",
    },
    settings: {
      sidebar: {
        modules: "Módulos",
        events: "Eventos",
        landing: "Landing page",
        product: "Produto",
        additionals: "Variáveis Adicionais",
      },
      additionals: {
        title: "Variáveis Adicionais da Loja",
        subtitle:
          "Estas são variáveis que serão usadas para a construção da URL dos produtos e da Landing page de cada loja.",
      },
      product: {
        title: "Configuração global de produtos",
        subtitle: "Permissões",
        canCreate: "As lojas podem",
        canEdit: "As lojas podem",
        canCreateCon: "novos produtos",
        canEditCon: "detalhes de produtos",
        create: "carregar",
        edit: "editar",
      },
      event: {
        title: "Configuração global de eventos",
        tabs: {
          permissions: "Permissões",
          graphicConfig: "Configuração gráfica",
        },
        permissions: {
          description:
            "Usar a configuração padrão do Proprietário para todas as lojas selecionadas",
        },
        extra: {
          title: "Opções globais",
          globalCounters: {
            label: "Os eventos exibirão contadores globais",
            tooltip:
              "Ao ativar esta opção, você verá a soma total de visualizações de todos os eventos simultâneos em vez das visualizações individuais de cada usuário.",
          },
        },
      },
      landing: {
        title: "Configuração global da landing page",
        one: "Clique no botão para começar a configurar sua página",
        permissions: {
          blocker: "Faça a etapa 1 primeiro",
          useDefault:
            "Aplicar as configurações da conta a todas as lojas selecionadas.",
          widgetDefault:
            "Esta permissão bloqueia a configuração dos widgets e do carrinho de compras.",
        },
      },
      userDetails: {
        success: "Dados salvos com sucesso.",
        error: "Ocorreu um erro, tente novamente.",
      },
      userRolesAndPermissions: {
        permissionsTable: {
          title: "Função e permissões",
          storeName: "Nome da Loja",
          rol: "Função",
          lastLog: "Último acesso",
          seePermission: "Ver permissões",
          orderOptions: {
            recent: "Mais recente",
            older: "Mais antigo",
            alphabet: "de A a Z",
            reverse: "de Z a A",
          },
        },
        permissionsDetail: {
          title: "@ Nome da loja",
          subTitle: "Serviços aos quais você tem acesso",
          permissionsDescription:
            "Terá apenas permissões para usar as funções deste serviço",
          permissionsDescriptionv2:
            "Terá permissões para usar as seguintes funções",
          permissionsDescriptionv3:
            "Terá permissões para usar as funções deste serviço",
          manager: {
            title: "Manager web",
            titlev2: "Manager",
            dashboard: "Dashboard",
            events: "Eventos",
            eventsv2: "Ver eventos",
            products: "Produtos",
            team: "Equipe",
          },
          proseller: {
            title: "App proseller",
            proseller: "Proseller",
            products: "Gestão de produtos",
            events: "Gestão de eventos",
            moderation: "Moderação",
            statistics: "Estatísticas",
            vod: "Gestão de vídeos gravados",
          },
          firaTV: {
            title: "Fira TV",
            moderation: "Moderação de comentários",
            VOD_mgmt: "Gestão de VOD",
            widgets: "Criação de widgets",
            statistics: "Estatísticas",
            collections: "Gestão de Coleções",
          },
          dashboard: {
            title: "Dashboard",
            statistics: "Estatísticas",
          },
          events: {
            title: "Eventos",
            event_mgmt: "Gestão de eventos",
            moderation: "Moderação",
            statistics: "Estatísticas",
          },
          products: {
            title: "Produtos",
            create: "Criar produtos",
            batch_load: "Carregamentos em massa",
            edit_products: "Editar produtos",
            delete_products: "Excluir produtos",
            management: "Gestão de produtos",
          },
        },
      },
    },
    userSecurity: {
      title: "Segurança",
      currentPwd: "Senha atual",
      email: "E-mail",
      changePwd: "Alterar senha",
      lastChange: "Última alteração em",
      forgotPwd: "Esqueci minha senha",
      forgotPwdv2: "Esqueceu sua senha?",
      newPwd: "Nova senha",
      confirmPwd: "Confirmar senha",
      securityBar: "Força da senha",
      cancelButton: "Cancelar",
      saveButton: "Salvar",
      noMatch: "As senhas não coincidem",
      wrongPwd: "As credenciais não coincidem",
    },
    createStoreForm: {
      title: "Criar nova loja",
      formTitle: "Informações da loja",
      name: "Nome da loja",
      url: "URL do site",
      language: "Idioma",
      currency: "Moeda",
      cancel: "Cancelar",
      create: "Criar loja",
    },
    selects: {
      contextChange: {
        yourAccount: "Sua conta owner",
      },
      storeTypes: {
        TEXTILE: "Têxtil",
        ELECTRONICS: "Eletrônicos",
        VARIOUS: "Diversos",
        COSMETICS: "Cosméticos",
      },
      currencies: {
        USD: "Dólar americano",
        EUR: "Euro",
        PEN: "Novo Sol peruano",
        BRL: "Real brasileiro",
        MXN: "Peso mexicano",
        COP: "Peso colombiano",
        CLP: "Peso chileno",
        ARS: "Peso argentino",
        GTQ: "Quetzal guatemalteco",
        NIO: "Córdoba nicaraguense",
        CRC: "Colón costarriquenha",
        HNL: "Lempira hondurenha",
        SVC: "Colón salvadorenha",
        CLF: "Unidade de Fomento",
        BOB: "Boliviano boliviano",
        PYG: "Guarani paraguaio",
      },
      unitSystems: {
        METRIC: "Sistema métrico",
        IMPERIAL: "Sistema imperial",
      },
    },
    store_settings: {
      general_info: {
        title: "Informações gerais",
        store_name: "Nome da loja",
        company: "Razão social da empresa",
        store_type: "Indústria da loja",
        language: "Idioma",
        country: "País",
        city: "Cidade",
        address: "Endereço",
        zip_code: "CEP",
        currency: "A moeda em que seus produtos são vendidos",
        time_zone: "Fuso horário",
        unit_system: "Sistema de unidades",
        weight_unit: "Unidades de peso padrão",
        description: "Descrição da loja",
      },
      brand_identity: {
        title: "Identidade da marca",
        brand_logo: "Logo da marca",
        brand_favicon: "Favicon",
        typography: "Tipografia",
        brand_colors: "Cores da marca",
        main_color: "Cor principal",
        secondary_color: "Cor secundária",
      },
      contact_info: {
        title: "Informações de contato",
        email: "E-mail",
        phoneNumber: "Número de telefone",
        web: "URL do site",
      },
      massification: {
        title: "Massificação",
        label: "Exibir o último live automaticamente",
      },
      cancel_btn: "Cancelar",
      save_btn: "Salvar",
      next_btn: "Próximo",
      prev_btn: "Voltar",
      error: "Ocorreu um erro, tente novamente.",
      success: "A loja foi editada com sucesso!",
    },
    inputMedia: {
      loadImg: "Carregar imagem",
      loadVideo: "Carregar vídeo",
      formats: "Formatos: ",
      sizes: "Tamanho recomendado ",
    },
    typeSelect: {
      title: "Tipos de eventos",
    },
  },
  modals: {
    pickStore: {
      title: "SELECIONE UMA LOJA",
      addStoreButton: "Adicionar uma loja",
    },
    profile: {
      edit_profile_modal: {
        title: "Perfil Pessoal",
        firstName: "Nome",
        lastName: "Sobrenome",
        birthdate: "Data de nascimento",
        gender: "Gênero",
        gender_radio: {
          male: "M",
          female: "F",
          other: "Outro",
        },
        gender_option: {
          male: "Masculino",
          female: "Feminino",
          other: "Outro",
        },
        mobilephone: "Número de telefone",
        email: "E-mail",
        email_short: "Email",
        username: "Usuário",
        password: "Senha",
        repeatPassword: "Repetir senha",
        cancel: "Cancelar",
        submit: "Salvar",
        edit: "Editar",
        alert_success: "As informações foram salvas com sucesso.",
        alert_error: "Ocorreu um erro, tente novamente.",
        avatar: "Foto de perfil",
        day: "DD",
        month: "MM",
        year: "AAAA",
        selectPlaceholder: "Selecionar",
        numberPlaceholder: "000-000-00",
        codePlaceholder: "+234",
      },
    },
    products: {
      load_modal: {
        title: "Importar produtos com EXCEL",
        message_1: "Baixar",
        message_2: "Planilha EXCEL",
        message_3: "de exemplo do formato requerido",
        drop_zone: "Adicionar arquivo",
        cancel_button: "Cancelar",
        load_button: "Enviar e continuar",
      },
      load_data_modal: {
        title: "Status do Carregamento",
        state: "Status",
        quantity: "Quantidade",
        valid: "Válidos",
        duplicated: "Duplicados",
        rejected: "Rejeitados",
        cancel_button: "Cancelar",
        save_button: "Salvar",
        rejected_file: "Produtos Rejeitados",
        duplicated_file: "Produtos Duplicados",
        approved_file: "Produtos Aprovados",
        download_file: "Baixar",
        info: "São os produtos que possuem algum erro na planilha excel",
      },
    },
    invitations: {
      title: "Convites Pendentes",
      invitation:
        "A Loja {{store}} está convidando você a fazer parte de sua equipe de trabalho",
      no_invitation: "Você não possui convites pendentes",
      accept: "Aceitar",
      dismiss: "Rejeitar",
      table: {
        store: "Loja",
        invitation: "Convite",
        accept: "Aceitar",
        yes: "Sim",
        no: "Não",
      },
      messages: {
        accepted: "Agora você faz parte da equipe de {{store}}",
        rejected: "Você rejeitou o convite de {{store}}",
        error: "Ocorreu um erro. Tente novamente mais tarde",
      },
    },
    success_message: {
      title: "Você criou seu evento com sucesso!",
      edited: "Você atualizou seu evento com sucesso!",
      description1: "Enviamos para seu e-mail todos os detalhes do evento ",
      description2: "para o dia ",
    },
    eventDetail: {
      addProducts: {
        title: "Produtos selecionados",
        filter: "Toque para pesquisar....",
        table: {
          name: "Nome",
          sku: "SKU",
          inventory: "Estoque",
          price: "Preço",
          discount: "Desconto",
        },
        add: "Adicionar",
        cancel: "Cancelar",
        messages: {
          error_generic:
            "Ocorreu um erro ao adicionar os produtos selecionados",
          error_1:
            "Não foi possível encontrar a instância com o id {{eventId}} e o status Iniciado",
          error_2:
            "Nenhuma instância de BroadcastProduct foi encontrada com o id {{productId}}",
          error_3: "Nenhum produto foi adicionado, pois todos já existem",
          error_4:
            "Não foi possível encontrar a instância com o id {{eventId}} e o status Agendado",
        },
      },
      deleteProducts: {
        title: "Excluir produto",
        message: "Tem certeza de que deseja excluir {{name}}?",
        yes: "Sim",
        no: "Não",
      },
      credentials: {
        title: "Configuração de transmissão",
        subTitle:
          "Copie e cole os códigos abaixo em sua plataforma de transmissão",
        integration: {
          title: "Configuração de integração",
          instruction_1:
            "Copie e cole o código abaixo na seção da sua página onde deseja que apareça o botão para direcionar ao “Live”",
          instruction_2:
            "Copie e cole o script abaixo no final da sua página para a execução da biblioteca da Fira",
        },
        credentials: {
          title: "Credenciais de transmissão",
          generate: "Gerar",
          server: "Servidor",
          key: "Chave",
          messages: {
            copied: "Copiado!",
            success: "Credenciais criadas com sucesso",
          },
        },
        messages: {
          invalid_id: "ID de Stream {{streamId}} inválido",
          error_saving: "Erro ao salvar as credenciais de transmissão",
          cant_generate:
            "Não é possível gerar credenciais para um evento não agendado",
        },
      },
      start: {
        title: "Iniciar transmissão",
        message_1:
          "Antes de começar o evento, certifique-se de que está transmitindo. Se iniciar o evento sem sinal de vídeo, não será possível gravá-lo.",
        message_2: "Você está pronto para começar o evento agora?",
        yes: "Sim",
        no: "Não",
      },
      finish: {
        title: "Finalizar transmissão",
        message: "Tem certeza de que deseja finalizar o live?",
        yes: "Sim",
        no: "Não",
      },
    },
    unsync: {
      title: "Desincronizar @ecommerce",
      content:
        "Tem certeza de que deseja desincronizar o e-commerce @ecommerce?",
      yes: "Sim",
      no: "Não",
    },
    insertVideo: {
      title: "Inserir vídeo",
      subtitle: "OPÇÕES DE INSERÇÃO",
      option: "Mostrar os controles do reprodutor.",
      cancel: "Cancelar",
      copy: "Copiar",
      messages: {
        copied: "Copiado com sucesso!",
        error: "Ocorreu um erro ao copiar. Tente novamente",
      },
    },
    videosList: {
      title: "Vídeos selecionados",
      table: {
        videoName: "Nome do vídeo",
        desc: "Descrição",
        teaser: "Teaser",
        products: "Produtos",
      },
      abandon: "Abandonar página",
      save: "Salvar e sair",
    },
    storeDetails: {
      title: "Tem certeza de que deseja excluir a loja?",
      desc: "Tenha em mente que isso não poderá ser desfeito.",
      cancel: "Cancelar",
      accept: "Aceitar",
    },
  },

  dialogs: {
    deleteProducts: {
      title: "Atenção!",
      singleTextContent: "Tem certeza de que deseja excluir {productName}?",
      multipleTextContent:
        "Tem certeza de que deseja excluir {quantity} produtos?",
      textContent: "Esta ação não pode ser desfeita.",
      deleteButton: "Excluir",
    },
    cancelButton: "Cancelar",
  },
  store: {
    edit_store_modal: {
      title_create: "Adicionar Nova Loja",
      title: "Perfil da Loja",
      store_name: "Nome da loja",
      store_id: "ID da loja",
      name: "Nome",
      desc: "Descrição",
      desc_2: "Descrição da loja",
      lang: "Idioma",
      currency: "Moeda",
      state: "Estado",
      city: "Cidade",
      country: "País",
      postCode: "Código Postal",
      address: "Endereço",
      save: "Salvar",
      cancel: "Cancelar",
      edit: "Editar",
      alert_success: "As informações foram salvas com sucesso",
      alert_success_create: "Sua loja {name} foi criada com sucesso",
    },
    brand_identity: {
      title: "Identidade da marca",
      edit: "Editar",
      brand_logo: "Logo da marca",
      upload: "Carregar imagem",
    },
    messages: {
      error: {
        file_is_not_image: "Erro ao carregar a imagem",
        default: "Encontramos um erro, tente novamente",
      },
    },
  },
  permission: {
    title: "Equipe",
    btncancel: "Cancelar",
    btnsend: "Enviar convite",
    subtitle: "Adicionar Membro",
    email: "E-mail",
    list: {
      title: "Permissões",
      subtitle: "O funcionário terá as seguintes permissões:",
      selectall: "Selecionar todos",
      dashboard: {
        title: "Dashboard",
        title_desc: "O usuário poderá acessar todas as informações do módulo",
        dashStatics: "Estatísticas",
        dashStatics_desc: "Informações da gestão realizada no mês",
      },
      event_item: {
        title: "Eventos",
        title_desc: "O usuário poderá acessar todas as informações do módulo",
        eventsManage: "Gestão de eventos",
        eventsManage_desc:
          "O usuário poderá realizar qualquer tipo de alteração nos eventos",
        eventsAddEvent: "Criar eventos",
        eventsAddEvent_desc: "O usuário poderá criar eventos",
        eventsEditEvent: "Editar eventos",
        eventsEditEvent_desc: "O usuário poderá editar eventos",
        eventsDeleteEvent: "Excluir eventos",
        eventsDeleteEvent_desc: "O usuário poderá excluir eventos",
        eventsModeration: "Moderação",
        eventsModeration_desc:
          "O usuário poderá moderar os eventos aos quais estiver atribuído",
        eventsShowStatics: "Estatísticas",
        eventsShowStatics_desc:
          "O usuário poderá visualizar as estatísticas pós-Live",
      },
      credential: {
        title: "Credenciais",
        title_desc: "O usuário poderá acessar todas as informações do módulo",
        credentialsAdd: "Criar credenciais",
        credentialsAdd_desc:
          "O usuário poderá criar e atribuir credenciais a outros usuários",
        credentialsShow: "Ver credenciais",
        credentialsShow_desc:
          "O usuário poderá ver suas credenciais e as de outros usuários",
        credentialsDelete: "Excluir credenciais",
        credentialsDelete_desc:
          "O usuário poderá excluir suas credenciais e as de outros usuários",
      },
      team: {
        title: "Equipe",
        title_desc: "O usuário poderá acessar todas as informações do módulo",
        teamAddMember: "Adicionar membros",
        teamAddMember_desc:
          "O usuário poderá convidar pessoas a fazer parte de sua equipe de trabalho",
        teamEditMember: "Editar membros",
        teamEditMember_desc:
          "O usuário poderá editar as permissões de outros membros da equipe",
        teamDeleteMember: "Excluir membros",
        teamDeleteMember_desc:
          "O usuário poderá excluir outros membros da equipe",
      },
      products: {
        title: "Produtos",
        title_desc: "O usuário poderá acessar todas as informações do módulo",
        productsAddProduct: "Criar produtos",
        productsAddProduct_desc: "O usuário poderá criar produtos",
        productsBatchUpload: "Carregamentos em massa",
        productsBatchUpload_desc:
          "O usuário poderá realizar carregamentos em massa",
        productsEditProduct: "Editar produtos",
        productsEditProduct_desc: "O usuário poderá editar produtos",
        productsDeleteProduct: "Excluir produtos",
        productsDeleteProduct_desc: "O usuário poderá excluir produtos",
      },
      firatv: {
        title: "Fira TV",
        title_desc: "O usuário poderá acessar todas as informações do módulo",
        firaTvRebroadcast: "Retransmitir",
        firaTvRebroadcast_desc:
          "O usuário poderá selecionar quais lives deseja que sejam exibidos na landing page",
        firaTvExportBroadcast: "Exportar",
        firaTvExportBroadcast_desc: "O usuário poderá exportar vídeos",
        firaTvDeleteBroadcast: "Excluir",
        firaTvDeleteBroadcast_desc: "O usuário poderá excluir vídeos",
      },
    },
    inactivate: "inativar",
    activate: "ativar",
    user: "Usuário",
    modal: {
      title: "Atenção!",
      desc: "Tem certeza de que deseja {status} {userName}?",
      accept: "Aceitar",
      cancel: "Cancelar",
    },
  },
  event_creation: {
    live: "Criar Live",
    generalRoute: "Detalhes gerais",
    teamRoute: "Equipe",
    productsRoute: "Produtos",
    paramsRoute: "Parâmetros",
    prevButton: "Voltar",
    nextButton: "Próximo",
    save: "Salvar",
    credentials: {
      title: "Selecione sua credencial",
      message:
        "Escolha a opção de credencial mais próxima de seu país, para ter uma melhor conexão",
    },
    general_details: {
      title: "Informações gerais",
      message: "Preencha as informações a seguir relacionadas ao seu evento",
      forms: {
        event_name: "Nome do evento",
        datetime: "Data e hora",
        desc: "Descrição do evento",
        select_categories: "Selecionar categorias",
        load_teaser: "Carregar teaser",
        load_image: "Carregar imagem",
        optional: "(Opcional)",
        teaser_info: "Vídeo com prévia do evento",
        file_format: "Formato de arquivo",
        recommended_size: "Tamanho recomendado",
        image_info: "Imagem de capa para seu evento",
        globalModeration: "Moderação global",
        individuallModeration: "Moderação individual",
        infoGlobal:
          "Selecione 'Moderação Global' se preferir que a moderação do chat seja gerenciada centralizadamente pela conta principal.",
        infoIndividual:
          "Selecione 'Moderação Individual' se desejar que cada loja tenha seu próprio chat e gerencie a moderação do chat de forma independente",
      },
      image_error:
        "A imagem selecionada possui um formato ou tamanho incorreto, tente novamente.",
    },
    team: {
      title: "Selecione sua Equipe",
      message: "Escolha os membros que estarão ativos no evento",
      placeholder: "Toque para pesquisar...",
      selected: "Selecionados",
      info: "O dono da conta, o(s) administrador(es) e o criador do live sempre estarão selecionados como moderadores.",
    },
    params: {
      interfaceTitle: "Selecione a configuração da sua interface de vídeo",
      interfaceMessage:
        "Escolha ou crie um modelo de acordo com as características que você precisa",
      interfaceButton: "Selecionar",
      interfaceAddMore: "Adicionar",
      colorSelectionTitle: "Cor principal da transmissão",
      colorSelectionMessage:
        "Selecione a cor que usará para destacar as interações de sua marca no chat",
      chat_and_web: "Chat e produtos Web",
      productsMockup: "Produtos",
      loginParamsTitle: "Habilitação do Login",

      loginParamsMessage:
        "Capture informações mais detalhadas de sua audiência habilitando o login",
      loginEnabled: "Login habilitado",
      addFields: "Adicionar Parâmetros Adicionais",
      requiredField: "Obrigatório",
      attachmentsTitle: "Documentos anexados",
      attachmentsMessage:
        "Carregue e selecione os elementos que você utilizará durante a sessão",
      loadingIcon: "Ícone de carregamento",
      shoppingBag: "Sacola de compras",
      likes: "Imagens de curtidas",
      words_to_moderate: "Palavras a moderar",
      fieldTypes: {
        username: "Nome de Usuário",
        text: "Texto",
        email: "E-mail",
        password: "Senha",
        tel: "Telefone",
        number: "Número",
      },
      broadcastingResolution: {
        title: "Orientação da transmissão",
        subtitle:
          "Selecione o sentido em que o live será realizado, este pode ser horizontal ou vertical",
        horizontal: "Transmissão horizontal",
        vertical: "Transmissão vertical",
      },
      terms_and_conditions: "Insira a URL dos Termos e Condições",
      terms_and_conditions_personal_data:
        "Insira a URL do tratamento de dados pessoais",
      default: "Padrão",
      custom: "Personalizado",
      drag: "Arraste seus documentos",
      add_word_placeholder: "Adicionar palavra...",
      attachments: "Anexos",
      load: "Carregar arquivo",
      colorPreview: "Pré-visualização da cor",
      buyButtonTitle: "Configuração do botão do produto",
      buyButtonMessage:
        "Personalize o texto do botão de venda de seus produtos",
      buyButtonDefault: "Padrão",
      buyButtonCustom: "Personalizado",
      buyButtonText: "Texto do botão",
      buyButtonSave: "Salvar",
      buyButtonPlaceholder: "Ex: Comprar",
      buyButtonPrev: "Pré-visualização",
      buyButtonProductName: "Nome do produto",
      buyButtonView: "Comprar",
      noSavedAlert:
        "Ops! Para continuar, clique em salvar para confirmar as informações",
    },
    incorrect_file_loaded: "Verifique se seu arquivo é ",
    fail: "Não foi possível criar o evento!",
    fail_message:
      "No momento estamos com problemas, por favor tente novamente mais tarde.",
    fail_btn: "Entendi",
  },
  dropzone: {
    title: "Arraste seus documentos",
  },
  toggle: {
    yes: "SIM",
    no: "NÃO",
  },
  widgets: {
    audienceResume: {
      title: "Resumo da situação",
      audiencia: "Audiência",
    },
    interactions: {
      title: "Interações",
      subtitle: "Neste espaço serão exibidas todas as",
      subtitle_2: "Quantidade total por tipo de interação",
      likes: "Curtidas",
      shares: "Compartilhamentos",
      messages: "Mensagens",
      clicks: "Cliques",
      total: "Total",
    },
    viewsByDevices: {
      title: "Visualização por dispositivo",
      desktop: "Desktop",
      mobile: "Mobile",
      other: "Outro",
    },
    documentation: {
      title1: "Melhore sua ",
      title2: "Campanha",
      title3: " para um melhor alcance",
      button: "Em breve",
    },
    card: {
      availableTime: "Tempo disponível",
      avgReproduction: "Média de reprodução",
    },
    highlights: {
      title: "Lives em destaque",
      subtitle: "Neste espaço serão exibidos 3 lives em destaque",
      empty: "Você não possui mais eventos",
      select: {
        likes: "Curtidas",
        shares: "Compartilhamentos",
        clicks: "Cliques",
        messages: "Mensagens",
        audience: "Audiência",
        filter: "Filtro",
      },
    },
  },
  calendar: {
    from: "De",
    to: "Até",
    january: "Janeiro",
    february: "Fevereiro",
    march: "Março",
    april: "Abril",
    may: "Maio",
    june: "Junho",
    july: "Julho",
    august: "Agosto",
    september: "Setembro",
    october: "Outubro",
    november: "Novembro",
    december: "Dezembro",
    sunday: "D",
    monday: "S",
    tuesday: "T",
    wednesday: "Q",
    thursday: "Q",
    friday: "S",
    saturday: "S",
  },
  tips: {
    main_store:
      "<strong>Obs:</strong> A loja marcada com ⭐ é aquela que será utilizada para eventos simultâneos e massivos.",
  },
  inputs: {
    searchPlaceholder: "Toque para pesquisar....",
    varKeyPlaceholder: "Nome da variável",
    varValuePlaceholder: "Valor padrão da variável",
  },
  forms: {
    name_label: "Nome",
    lastname_label: "Sobrenome",
    user_label: "Usuário",
    email_label: "E-mail",
    phonenumber_label: "Número de telefone",
    whatsapp_label: "WhatsApp para receber seus pedidos",
  },
  table: {
    header: {
      name: "Nome",
      email: "E-mail",
      invite_date: "Data do convite",
      store: "Loja",
      role: "Função",
      status: "Status",
    },
  },
  buttons: {
    save: "Salvar",
    cancel: "Cancelar",
    next: "Próximo",
    back: "Voltar",
    create_account: "Criar Conta",
    copy_url: "Copiar URL",
    try_again: "Tentar novamente",
    preview: "Pré-visualização",
    setup: "Configurar",
    select_all: "Selecionar todos",
    add_vars: "Adicionar Parâmetros Adicionais",
    resend: "Reenviar",
    annul: "Anular",
    continue: "Continuar",
  },
  alerts: {
    success: "Alteração salva",
    url_copy: "URL copiada!",
    landing_error: "Loja já possui landing page:",
    savings: "Salvando",
    vars_error: "Tanto a chave quanto o valor devem ser preenchidos.",
    vars_url_error:
      "Tanto a chave quanto o valor devem ser parâmetros válidos de URL.",
    invite_success: "Convite enviado!",
    invite_cancelled: "Convite cancelado",
    error: "Encontramos um erro, tente novamente.",
    image_will_delete: "Esta imagem será excluída ao salvar",
  },
  invitation: {
    status: {
      CANCELLED: "Cancelado",
      PENDING: "Pendente",
      ACCEPTED: "Aceito",
      CLOSED: "Rejeitado",
    },
  },
  user: {
    role: {
      OPERATOR: "Operador",
      MODERATOR: "Moderador",
      MANAGER: "Administrador",
    },
  },
  wizard: {
    steps: {
      COLOR: "Cor",
      BUTTON: "Botão de compra",
      ORIENTATION: "Orientação",
      LOGIN: "Login",
    },
  },
};
